<script setup>
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ParrainageComponent from '@/components/Contents/ParrainageComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';

import { onMounted, ref } from 'vue';
const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
    scrollToTop();
});

const scrollToTop = () => {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

    }
}

</script>
<template>
    <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />
    <div class="pb-12 bg-slate-100" id="constats" data-spy="scroll" ref="componentRef">

        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <span class="uppercase font-semibold text-amber-500">Entreprises, votre intérêt</span>
                    <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">
                        Constats sur le marché.</h2>
                    <ul class="list-disc mb-3 ml-[2.7rem]">
                        <li class="text-xl text-gray-600 my-4">La pénurie de développeurs codeurs est manifeste sur tous les
                            secteurs industriels, particulièrement front-end et back-end en 2023.</li>
                        <li class="text-xl text-gray-600 my-4">Les métiers de codage restent pour autant très associé au
                            mode de fonctionnement des entreprises et les candidats doivent avoir des formations
                            complémentaires à l’embauche.</li>
                        <li class="text-xl text-gray-600 my-4">La production de lignes de codage informatique se fait de
                            moins en moins où l’entreprise physique est située.</li>
                        <li class="text-xl text-gray-600 my-4">Cela permet aux entreprises de maximiser leurs budgets avec des
                            niveaux de tarifications très liées aux localisations géographiques elles-mêmes.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Response -->
    <div class="py-12 bg-white" id="reponse" data-spy="scroll">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Réponse de HIGHFIVE University</h2>
                    <ul class="list-disc mb-3 ml-[2.7rem]">
                        <li class="text-xl text-gray-600 my-4">HIGHFIVE University constitue l’école de codage du Groupe
                            Vipp qui s’est implanté avec succès en Afrique depuis 10 ans (3000 collaborateurs).</li>
                        <li class="text-xl text-gray-600 my-4">La 1ère Université est basée au Bénin et nous allons en
                            ouvrir très prochainement de nouvelles : Cameroun, Togo, Rwanda… pour répondre à la demande des
                            marchés européens et africains.</li>
                        <li class="text-xl text-gray-600 my-4">Nous sélectionnons sur concours des étudiants à hauts
                            potentiels en termes d’appétence et de compétences comportementales en affinité avec ces
                            métiers.</li>
                        <li class="text-xl text-gray-600 my-4">Nous les formons en bootcamp sur 6 mois : apprentissage
                            itératif intensif dès la première semaine (théorie / applications) qui les rendent rapidement
                            très opérationnels.</li>
                        <li class="text-xl text-gray-600 my-4">La formation est renforcée pour les besoins d’une entreprise,
                            sous l’encadrement de notre corps enseignant, et sanctionné par un contrôle continu exigeant.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Sponsorship -->
    <div class="py-12 bg-slate-100" id="parrainage" data-spy="scroll">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Parrainer un étudiant</h2>
                    <ul class="list-disc mb-3 ml-[2.7rem]">
                        <li class="text-xl text-gray-600 my-4">Le stagiaire que vous parrainez, prend en charge un projet
                            sur 16 mois, après 6 mois de formation en continuant d’être formé et suivi par nos équipes.</li>
                        <li class="text-xl text-gray-600 my-4">Il est totalement dédié et s’adapte très vite au mode de
                            fonctionnement et à la culture de votre organisation.</li>
                        <li class="text-xl text-gray-600 my-4">A l’issue de son cursus pédagogique de 24 mois au total, il est totalement opérationnel.</li>
                        <li class="text-xl text-gray-600 my-4">Vous pouvez alors opter ensemble pour son intégration
                            définitive ou arrêter la collaboration.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Interest -->
    <div class="py-12 bg-white" id="interesse" data-spy="scroll">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <span class="uppercase font-semibold text-amber-500">Contactez-nous</span>
                    <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Intéressé ?</h2>
                    <p class="text-xl text-gray-600 my-4 mb-3 lead">
                        Vous souhaitez en savoir plus ou postuler pour accueillir un étudiant ?,
                       
                        <router-link to="/ContactEntreprises">
                            <a class="text-amber-500">cliquer ici</a>
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- parrainage footer -->
    <ParrainageComponent />
    <!-- Footer -->
    <pagefooter />
</template>

