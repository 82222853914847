<template>

<ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />
  
  
    <div class="py-10 bg-white" ref="componentRef">
      <div class="  md:mx-auto mx-5">
        <div class="flex justify-center">
          <div class="lg:w-2/3 md:w-full w-full">
            <div class="text-center">
              <h1 class="text-4xl font-bold">Mentions légales</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="pb-10 bg-white">
      <div class="  md:mx-auto mx-5">
        <div class="flex justify-center">
          <div class="lg:w-2/3 md:w-full w-full">
            <div class="mt-5 mb-5 flex">
              <div>
                <h3 class="text-xl font-bold">Droits d'auteur / Copyright :</h3>
                <p class="">
                  L'accès au site ne confère aucun droit autre que celui d'en visiter le contenu.
                </p>
                <p class="">
                  L'intégralité du site highfiveuniversity.bj est protégée par les législations béninoises, française et international relatives à la propriété intellectuelle. Tous les droits de reproduction sont
                  réservés, y compris pour les documents téléchargeables.
                </p>
                <p class="">
                  L'ensemble des textes, graphismes, icônes, photographies, logos, vidéos, sons, marques (…) et plus généralement l'ensemble des éléments composant le site
                  highfiveuniversity.bj et le site lui-même ne peuvent faire l'objet d'une quelconque représentation ou reproduction, intégrale ou partielle, sur quelque support
                  que ce soit, sans l'autorisation expresse et préalable de Glitter SAS.
                </p>
                <p class="mb-1 "><span class="text-dark font-semibold">Design : </span>Michaël Gramm (contact@michaelgramm.com).</p>
                <p class="mb-4 "><span class="text-dark font-semibold">Développement : </span>Benjamin Roche (benjamin.roche4@gmail.com).</p>
              </div>
            </div>
            <div class="mt-5 mb-5 flex">
              <div>
                <h3 class="text-xl font-bold">Responsabilité :</h3>
                <p class="">
                  Les informations communiquées sur le site highfiveuniversity.bj sont fournies à titre indicatif, elles sont non contractuelles et ne sauraient engager la
                  responsabilité de Glitter SAS.
                </p>
                <p class="">
                  de Glitter SAS se réserve le droit, à tout moment et sans préavis, d'apporter des améliorations et/ou modifications au site highfiveuniversity.bj.
                </p>
                <p class="">
                  La responsabilité du de Glitter SAS ne saurait être engagée pour les dommages de toute nature, directs ou indirects, résultant de l'utilisation du site, de l'impossibilité
                  d'y accéder ainsi qu'aux sites qui y sont liés, des omissions et/ou erreurs que pourrait contenir le site.
                </p>
              </div>
            </div>
            <p class="mb-1 "><span class="text-dark font-semibold">L'éditeur du site est : </span>Glitter SAS.</p>
            <p class="mb-1 "><span class="text-dark font-semibold">Adresse : </span>11, boulevard Brune – 75014 Paris.</p>
            <p class="mb-1 "><span class="text-dark font-semibold">RCS : </span> 520 293 226 00020.</p>
            <p class="mb-1 "><span class="text-dark font-semibold">N° TVA intracommunautaire : </span> FR19 520 293 226.</p>
            <p class="mb-1  mt-5"><span class="text-dark font-semibold">Email : </span> contact@vippinterstis.com.</p>
            <p class="mb-1  mt-5"><span class="text-dark font-semibold">Représentant légal et Directeur de la publication : </span> CE BERC, Représentant de la personne morale Présidente.</p>
            <p class="mb-1 "><span class="text-dark font-semibold">Hébergement : </span> OVH SAS – 2, rue Kellermann, 59100 Roubaix.</p>
            <div class="mt-5 flex">
              <div>
                <h3 class="text-xl font-bold">Observations diverses :</h3>
                <p class="">Toute remarque concernant le contenu ou le fonctionnement du site highfiveuniversity.bj peut être adressée à contact@vippinterstis.com.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
   <!-- Info student  -->
  <infostudent />
  <!-- Footer -->
  <pagefooter />
  </template>
  
  <script setup>
import { ref, onMounted } from 'vue';
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';


const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
  scrollToTop();
});

const scrollToTop = () => {
  if (componentRef.value) {
    componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

  }
}

  </script>