<template>
    <ButtonTop class="fixed flex items-center justify-center w-10 h-10 rounded-full bottom-8 right-8 bg-sky-500" />
    <div class="py-12 bg-white" ref="componentRef">


        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <!-- <div class="" v-if="message">
                <div class="w-full">
                    <div class="py-8 mx-auto text-5xl text-center text-white bg-amber-300" v-if="message.code == 409">
                        Erreur: Votre candidature existe déjà.
                    </div>
                    <div class="py-8 mx-auto text-5xl text-center text-white bg-green-400" v-if="message.code == 200">
                        Votre candidature a été enregistré avec succès
                    </div>
                </div>
            </div> -->
            <form @submit.prevent="sendData">
                <div class="container mx-auto">

                    <!-- Title -->
                    <div class="col-span-12 mb-6 lg:offset-1 lg:col-span-10 md:col-span-10 md:offset-1">
                        <div class="mb-4 ">
                            <span class="font-bold uppercase text-amber-500">Candidature</span>
                            <h1 class="flex my-4 text-4xl font-bold xl:text-4xl text-indigo-950">Formulaire de
                                candidature</h1>


                            <p class="my-2 text-xl text-gray-600">Nous vous souhaitons la bienvenue sur notre page
                                d'inscription
                                en ligne</p>
                            <p class="my-1 text-xl text-gray-600">Nous souhaitons mieux vous connaîtres pour cette
                                première
                                phase de sélection</p>
                        </div>
                    </div>

                    <div class="mt-10">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-8 h-8 mb-4 text-amber-500">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>
                            <h2 class="font-bold">1. Identité du candidat</h2>
                            <p class="my-1 mb-0 text-sm text-gray-600">Renseignez les informations exactement telles
                                qu'elles
                                figurent sur votre pièce d'identité</p>
                        </div>
                        <div class="">
                            <div class="mb-4 ">
                                <div class="border-b-black border-spacing-5">
                                    <div class="mb-3 2">
                                        <label>Civilité<span class="text-red-500">*</span></label>
                                        <select type="text" placeholder="Choisissez votre civilité"
                                            class="w-full text-black mt-[.5rem] border p-3 rounded-lg" required
                                            v-model="civility">
                                            <option value=""></option>
                                            <option value="Monsieur">Monsieur</option>
                                            <option value="Madame">Madame</option>
                                            <option value="Mademoiselle">Mademoiselle</option>
                                        </select>
                                    </div>
                                    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                        <div class="mb-3">
                                            <label>Prénom<span class="text-red-500">*</span></label>
                                            <input type="text" placeholder="John"
                                                class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                                v-model="first_name">
                                        </div>
                                        <div class="mb-3">
                                            <label for="lname">Nom<span class="text-red-500">*</span></label>
                                            <input type="text" placeholder="Doe"
                                                class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                                v-model="last_name">
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                        <div class="mb-3">
                                            <label for="date">Date de naissance<span
                                                    class="text-red-500">*</span></label>
                                            <input type="date" placeholder="Ex: 30-09-1996"
                                                class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                                v-model="birth">
                                            <span class="my-1 text-sm italic text-black">A renseigner au formation
                                                JJ-MM-YYYY.</span>
                                        </div>
                                        <div class="mb-3">
                                            <label for="pays">Nationalité<span class="text-red-500">*</span></label>
                                            <select name="pays"
                                                class="w-full mt-[.5rem] border p-3 rounded-lg text-black" required
                                                v-model="nationality">
                                                <option value="France" selected="selected">Benin</option>

                                                <option value="Afghanistan">Afghanistan </option>
                                                <option value="Afrique_Centrale">Afrique_Centrale </option>
                                                <option value="Afrique_du_sud">Afrique_du_Sud </option>
                                                <option value="Albanie">Albanie </option>
                                                <option value="Algerie">Algerie </option>
                                                <option value="Allemagne">Allemagne </option>
                                                <option value="Andorre">Andorre </option>
                                                <option value="Angola">Angola </option>
                                                <option value="Anguilla">Anguilla </option>
                                                <option value="Arabie_Saoudite">Arabie_Saoudite </option>
                                                <option value="Argentine">Argentine </option>
                                                <option value="Armenie">Armenie </option>
                                                <option value="Australie">Australie </option>
                                                <option value="Autriche">Autriche </option>
                                                <option value="Azerbaidjan">Azerbaidjan </option>

                                                <option value="Bahamas">Bahamas </option>
                                                <option value="Bangladesh">Bangladesh </option>
                                                <option value="Barbade">Barbade </option>
                                                <option value="Bahrein">Bahrein </option>
                                                <option value="Belgique">Belgique </option>
                                                <option value="Belize">Belize </option>
                                                <option value="Benin">Benin </option>
                                                <option value="Bermudes">Bermudes </option>
                                                <option value="Bielorussie">Bielorussie </option>
                                                <option value="Bolivie">Bolivie </option>
                                                <option value="Botswana">Botswana </option>
                                                <option value="Bhoutan">Bhoutan </option>
                                                <option value="Boznie_Herzegovine">Boznie_Herzegovine </option>
                                                <option value="Bresil">Bresil </option>
                                                <option value="Brunei">Brunei </option>
                                                <option value="Bulgarie">Bulgarie </option>
                                                <option value="Burkina_Faso">Burkina_Faso </option>
                                                <option value="Burundi">Burundi </option>

                                                <option value="Caiman">Caiman </option>
                                                <option value="Cambodge">Cambodge </option>
                                                <option value="Cameroun">Cameroun </option>
                                                <option value="Canada">Canada </option>
                                                <option value="Canaries">Canaries </option>
                                                <option value="Cap_vert">Cap_Vert </option>
                                                <option value="Chili">Chili </option>
                                                <option value="Chine">Chine </option>
                                                <option value="Chypre">Chypre </option>
                                                <option value="Colombie">Colombie </option>
                                                <option value="Comores">Colombie </option>
                                                <option value="Congo">Congo </option>
                                                <option value="Congo_democratique">Congo_democratique </option>
                                                <option value="Cook">Cook </option>
                                                <option value="Coree_du_Nord">Coree_du_Nord </option>
                                                <option value="Coree_du_Sud">Coree_du_Sud </option>
                                                <option value="Costa_Rica">Costa_Rica </option>
                                                <option value="Cote_d_Ivoire">Côte_d_Ivoire </option>
                                                <option value="Croatie">Croatie </option>
                                                <option value="Cuba">Cuba </option>

                                                <option value="Danemark">Danemark </option>
                                                <option value="Djibouti">Djibouti </option>
                                                <option value="Dominique">Dominique </option>

                                                <option value="Egypte">Egypte </option>
                                                <option value="Emirats_Arabes_Unis">Emirats_Arabes_Unis </option>
                                                <option value="Equateur">Equateur </option>
                                                <option value="Erythree">Erythree </option>
                                                <option value="Espagne">Espagne </option>
                                                <option value="Estonie">Estonie </option>
                                                <option value="Etats_Unis">Etats_Unis </option>
                                                <option value="Ethiopie">Ethiopie </option>

                                                <option value="Falkland">Falkland </option>
                                                <option value="Feroe">Feroe </option>
                                                <option value="Fidji">Fidji </option>
                                                <option value="Finlande">Finlande </option>
                                                <option value="France">France </option>

                                                <option value="Gabon">Gabon </option>
                                                <option value="Gambie">Gambie </option>
                                                <option value="Georgie">Georgie </option>
                                                <option value="Ghana">Ghana </option>
                                                <option value="Gibraltar">Gibraltar </option>
                                                <option value="Grece">Grece </option>
                                                <option value="Grenade">Grenade </option>
                                                <option value="Groenland">Groenland </option>
                                                <option value="Guadeloupe">Guadeloupe </option>
                                                <option value="Guam">Guam </option>
                                                <option value="Guatemala">Guatemala</option>
                                                <option value="Guernesey">Guernesey </option>
                                                <option value="Guinee">Guinee </option>
                                                <option value="Guinee_Bissau">Guinee_Bissau </option>
                                                <option value="Guinee equatoriale">Guinee_Equatoriale </option>
                                                <option value="Guyana">Guyana </option>
                                                <option value="Guyane_Francaise ">Guyane_Francaise </option>

                                                <option value="Haiti">Haiti </option>
                                                <option value="Hawaii">Hawaii </option>
                                                <option value="Honduras">Honduras </option>
                                                <option value="Hong_Kong">Hong_Kong </option>
                                                <option value="Hongrie">Hongrie </option>

                                                <option value="Inde">Inde </option>
                                                <option value="Indonesie">Indonesie </option>
                                                <option value="Iran">Iran </option>
                                                <option value="Iraq">Iraq </option>
                                                <option value="Irlande">Irlande </option>
                                                <option value="Islande">Islande </option>
                                                <option value="Israel">Israel </option>
                                                <option value="Italie">italie </option>

                                                <option value="Jamaique">Jamaique </option>
                                                <option value="Jan Mayen">Jan Mayen </option>
                                                <option value="Japon">Japon </option>
                                                <option value="Jersey">Jersey </option>
                                                <option value="Jordanie">Jordanie </option>

                                                <option value="Kazakhstan">Kazakhstan </option>
                                                <option value="Kenya">Kenya </option>
                                                <option value="Kirghizstan">Kirghizistan </option>
                                                <option value="Kiribati">Kiribati </option>
                                                <option value="Koweit">Koweit </option>

                                                <option value="Laos">Laos </option>
                                                <option value="Lesotho">Lesotho </option>
                                                <option value="Lettonie">Lettonie </option>
                                                <option value="Liban">Liban </option>
                                                <option value="Liberia">Liberia </option>
                                                <option value="Liechtenstein">Liechtenstein </option>
                                                <option value="Lituanie">Lituanie </option>
                                                <option value="Luxembourg">Luxembourg </option>
                                                <option value="Lybie">Lybie </option>

                                                <option value="Macao">Macao </option>
                                                <option value="Macedoine">Macedoine </option>
                                                <option value="Madagascar">Madagascar </option>
                                                <option value="Madère">Madère </option>
                                                <option value="Malaisie">Malaisie </option>
                                                <option value="Malawi">Malawi </option>
                                                <option value="Maldives">Maldives </option>
                                                <option value="Mali">Mali </option>
                                                <option value="Malte">Malte </option>
                                                <option value="Man">Man </option>
                                                <option value="Mariannes du Nord">Mariannes du Nord </option>
                                                <option value="Maroc">Maroc </option>
                                                <option value="Marshall">Marshall </option>
                                                <option value="Martinique">Martinique </option>
                                                <option value="Maurice">Maurice </option>
                                                <option value="Mauritanie">Mauritanie </option>
                                                <option value="Mayotte">Mayotte </option>
                                                <option value="Mexique">Mexique </option>
                                                <option value="Micronesie">Micronesie </option>
                                                <option value="Midway">Midway </option>
                                                <option value="Moldavie">Moldavie </option>
                                                <option value="Monaco">Monaco </option>
                                                <option value="Mongolie">Mongolie </option>
                                                <option value="Montserrat">Montserrat </option>
                                                <option value="Mozambique">Mozambique </option>

                                                <option value="Namibie">Namibie </option>
                                                <option value="Nauru">Nauru </option>
                                                <option value="Nepal">Nepal </option>
                                                <option value="Nicaragua">Nicaragua </option>
                                                <option value="Niger">Niger </option>
                                                <option value="Nigeria">Nigeria </option>
                                                <option value="Niue">Niue </option>
                                                <option value="Norfolk">Norfolk </option>
                                                <option value="Norvege">Norvege </option>
                                                <option value="Nouvelle_Caledonie">Nouvelle_Caledonie </option>
                                                <option value="Nouvelle_Zelande">Nouvelle_Zelande </option>

                                                <option value="Oman">Oman </option>
                                                <option value="Ouganda">Ouganda </option>
                                                <option value="Ouzbekistan">Ouzbekistan </option>

                                                <option value="Pakistan">Pakistan </option>
                                                <option value="Palau">Palau </option>
                                                <option value="Palestine">Palestine </option>
                                                <option value="Panama">Panama </option>
                                                <option value="Papouasie_Nouvelle_Guinee">Papouasie_Nouvelle_Guinee
                                                </option>
                                                <option value="Paraguay">Paraguay </option>
                                                <option value="Pays_Bas">Pays_Bas </option>
                                                <option value="Perou">Perou </option>
                                                <option value="Philippines">Philippines </option>
                                                <option value="Pologne">Pologne </option>
                                                <option value="Polynesie">Polynesie </option>
                                                <option value="Porto_Rico">Porto_Rico </option>
                                                <option value="Portugal">Portugal </option>

                                                <option value="Qatar">Qatar </option>

                                                <option value="Republique_Dominicaine">Republique_Dominicaine </option>
                                                <option value="Republique_Tcheque">Republique_Tcheque </option>
                                                <option value="Reunion">Reunion </option>
                                                <option value="Roumanie">Roumanie </option>
                                                <option value="Royaume_Uni">Royaume_Uni </option>
                                                <option value="Russie">Russie </option>
                                                <option value="Rwanda">Rwanda </option>

                                                <option value="Sahara Occidental">Sahara Occidental </option>
                                                <option value="Sainte_Lucie">Sainte_Lucie </option>
                                                <option value="Saint_Marin">Saint_Marin </option>
                                                <option value="Salomon">Salomon </option>
                                                <option value="Salvador">Salvador </option>
                                                <option value="Samoa_Occidentales">Samoa_Occidentales</option>
                                                <option value="Samoa_Americaine">Samoa_Americaine </option>
                                                <option value="Sao_Tome_et_Principe">Sao_Tome_et_Principe </option>
                                                <option value="Senegal">Senegal </option>
                                                <option value="Seychelles">Seychelles </option>
                                                <option value="Sierra Leone">Sierra Leone </option>
                                                <option value="Singapour">Singapour </option>
                                                <option value="Slovaquie">Slovaquie </option>
                                                <option value="Slovenie">Slovenie</option>
                                                <option value="Somalie">Somalie </option>
                                                <option value="Soudan">Soudan </option>
                                                <option value="Sri_Lanka">Sri_Lanka </option>
                                                <option value="Suede">Suede </option>
                                                <option value="Suisse">Suisse </option>
                                                <option value="Surinam">Surinam </option>
                                                <option value="Swaziland">Swaziland </option>
                                                <option value="Syrie">Syrie </option>

                                                <option value="Tadjikistan">Tadjikistan </option>
                                                <option value="Taiwan">Taiwan </option>
                                                <option value="Tonga">Tonga </option>
                                                <option value="Tanzanie">Tanzanie </option>
                                                <option value="Tchad">Tchad </option>
                                                <option value="Thailande">Thailande </option>
                                                <option value="Tibet">Tibet </option>
                                                <option value="Timor_Oriental">Timor_Oriental </option>
                                                <option value="Togo">Togo </option>
                                                <option value="Trinite_et_Tobago">Trinite_et_Tobago </option>
                                                <option value="Tristan da cunha">Tristan de cuncha </option>
                                                <option value="Tunisie">Tunisie </option>
                                                <option value="Turkmenistan">Turmenistan </option>
                                                <option value="Turquie">Turquie </option>

                                                <option value="Ukraine">Ukraine </option>
                                                <option value="Uruguay">Uruguay </option>

                                                <option value="Vanuatu">Vanuatu </option>
                                                <option value="Vatican">Vatican </option>
                                                <option value="Venezuela">Venezuela </option>
                                                <option value="Vierges_Americaines">Vierges_Americaines </option>
                                                <option value="Vierges_Britanniques">Vierges_Britanniques </option>
                                                <option value="Vietnam">Vietnam </option>

                                                <option value="Wake">Wake </option>
                                                <option value="Wallis et Futuma">Wallis et Futuma </option>

                                                <option value="Yemen">Yemen </option>
                                                <option value="Yougoslavie">Yougoslavie </option>

                                                <option value="Zambie">Zambie </option>
                                                <option value="Zimbabwe">Zimbabwe </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                        <div class="mb-3">
                                            <label for="piece">Pièce d'identité...<span
                                                    class="text-red-500">*</span></label>
                                            <select placeholder="Choisissez votre pièce d'identité"
                                                class="w-full mt-[.5rem] border p-3 rounded-lg text-black" required
                                                v-model="identity_document">
                                                <option value=""></option>
                                                <option value="CNI">CNI</option>
                                                <option value="CIP">CIP</option>
                                                <option value="Carte LEPI">Carte LEPI</option>
                                                <option value="Passeport">Passeport</option>
                                                <option value="Carte biométrique">Carte biométrique</option>
                                            </select>
                                        </div>
                                        <div class="mb-3">
                                            <label for="cni">N° de la pièce<span class="text-red-500">*</span></label>
                                            <input type="text" placeholder="Ex: 11AI911AD"
                                                class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                                v-model="document_number">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="w-full ">

                <div class="mt-10">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8 mb-4 text-amber-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                        </svg>
                        <h2 class="font-bold">2. Coordonnées</h2>
                        <p class="my-1 mb-0 text-sm text-gray-600">Indiquez les contacts sur lesquels nos recruteurs
                            peuvent
                            immédiatement vous appeler.</p>
                    </div>
                    <div class="">
                        <div class="mb-4 ">
                            <div class="border-b-black border-spacing-5">
                                <div class="mb-3 2">
                                    <label for="email">Email<span class="text-red-500">*</span></label>
                                    <input type="email" placeholder="john.doe@gmail.com"
                                        class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                        v-model="email">
                                </div>
                                <div class="mb-3 2">
                                    <label for="adresse">Adresse<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Votre adresse exact..."
                                        class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                        v-model="address">
                                </div>
                                <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                    <div class="mb-3">
                                        <label for="ville">Ville<span class="text-red-500">*</span></label>
                                        <input type="text" placeholder="Votre ville..."
                                            class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                            v-model="city">
                                    </div>
                                    <div class="mb-3">
                                        <label for="quartier">Quartier<span class="text-red-500">*</span></label>
                                        <input type="text" placeholder="Votre quartier"
                                            class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                            v-model="district">
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                    <div class="mb-3">
                                        <label for="phone">Téléphone<span class="text-red-500">*</span></label>
                                        <input type="tel" placeholder="Ex: 62871921"
                                            class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                            v-model="phone">
                                        <span class="my-1 text-sm italic text-black">Le numéro est à saisir sans espace
                                            et
                                            sans
                                            signe de ponctuation.</span>
                                    </div>
                                    <div class="mb-3">
                                        <label for="phone">Téléphone secondaire</label>
                                        <input type="tel" placeholder="(facultatif)"
                                            class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600"
                                            v-model="phone2">
                                        <span class="my-1 text-sm italic text-black">Le numéro est à saisir sans espace
                                            et
                                            sans
                                            signe de ponctuation.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="w-full">

                <div class="mt-10">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8 mb-4 text-amber-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                        </svg>
                        <h2 class="font-bold">3. Etudes et Formations</h2>
                        <p class="my-1 mb-0 text-sm text-gray-600">Rappelez-vous que le niveau minimum requis pour vous
                            inscrire
                            est le niveau BAC+2. Si vous avez plusieurs diplômes de l'enseignement supérieur, indiquer
                            le
                            plus
                            élevé</p>
                    </div>
                    <div class="">
                        <div class="mb-4 ">
                            <div class="border-b-black border-spacing-5">
                                <div class="mb-3 2">
                                    <label for="niveau">Niveau d'étude<span class="text-red-500">*</span></label>
                                    <select placeholder="Choisissez votre niveau d'étude..."
                                        class="w-full text-black mt-[.5rem] border p-3 rounded-lg" required
                                        v-model="study_level">
                                        <option value=""></option>
                                        <option value="BAC">BAC </option>
                                        <option value="BAC +1">BAC +1</option>
                                        <option value="BAC +2">BAC +2</option>
                                        <option value="License">License</option>
                                        <option value="Master">Master</option>
                                        <option value="Doctorat">Doctorat</option>
                                    </select>
                                </div>
                                <div class="mb-3 2">
                                    <label for="annee">Année d'obtention ou dernière année d'étude<span
                                            class="text-red-500">*</span></label>
                                    <input type="number" placeholder="Ex: 2022"
                                        class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                        v-model="year_graduated">
                                </div>
                                <div class="mb-3 2">
                                    <label for="university">Université<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Votre université..."
                                        class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                        v-model="university">
                                </div>
                                <div class="mb-3 2">
                                    <label for="diplome">Nom du diplôme ou filière<span
                                            class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Nom de votre diplôme..."
                                        class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                        v-model="graduated_name">
                                </div>
                                <div class="mb-3 2">
                                    <label for="situation">Quelle est votre situation actuelle ?<span
                                            class="text-red-500">*</span></label>
                                    <select placeholder="Choisissez votre situation actuelle..."
                                        class="w-full text-black mt-[.5rem] border p-3 rounded-lg" required
                                        v-model="actual_situation">
                                        <option value=""></option>
                                        <option value="Etudiant">Etudiant</option>
                                        <option value="Sans activité professionnelle">Sans activité professionnelle
                                        </option>
                                        <option value="Salarié">Salarié</option>
                                        <option value="Entrepreneur">Entrepreneur</option>
                                    </select>
                                </div>
                                <div class="mb-3 2">
                                    <label for="anglais">Quelle est votre niveau d'anglais ?<span
                                            class="text-red-500">*</span></label>
                                    <select type="text" placeholder="Choisissez votre niveau d'anglais..."
                                        class="w-full text-black mt-[.5rem] border p-3 rounded-lg" required
                                        v-model="english_level">
                                        <option value=""></option>
                                        <option value="Débutant">Débutant</option>
                                        <option value="Intermédiaire">Intermédiaire</option>
                                        <option value="Courant">Courant</option>
                                        <option value="Bilingue">Bilingue</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="w-full">


                <div class="mt-10">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8 mb-4 text-amber-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                        </svg>
                        <h2 class="font-bold">4. Motivations et attentes</h2>
                        <p class="my-1 mb-0 text-sm text-gray-600">Parlez-nous brièvement de vous et soyez sincère.</p>
                    </div>
                    <div class="">
                        <div class="mb-4 ">
                            <div class="border-b-black border-spacing-5">
                                <div class="mb-3 2">
                                    <label>Pourquoi voudriez-vous suivre cette formation ?<span
                                            class="text-red-500">*</span></label>
                                    <textarea class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600"
                                        name="Message" id="" cols="10" rows="2" placeholder="Votre message..." required
                                        v-model="why_us"></textarea>
                                </div>
                                <div class="mb-3 2">
                                    <label>Quelles sont vos attentes par rapport à cette formation ?<span
                                            class="text-red-500">*</span></label>
                                    <textarea class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600"
                                        name="Message" id="" cols="10" rows="2" placeholder="Votre message..." required
                                        v-model="your_expectations"></textarea>
                                </div>
                                <div class="mb-3 2">
                                    <label>Comment imaginez-vous le métier de développeur codeur<span
                                            class="text-red-500">*</span></label>
                                    <textarea class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600"
                                        name="Message" id="" cols="10" rows="2" placeholder="Votre message..." required
                                        v-model="just_imagin"></textarea>
                                </div>
                                <div class="mb-3 2">
                                    <label>Comment est-ce que vous vous projeter dans 3 ans, 5 ans ?<span
                                            class="text-red-500">*</span></label>
                                    <textarea class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600"
                                        name="Message" id="" cols="10" rows="2" placeholder="Votre message..." required
                                        v-model="your_futur"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="w-full">

                <div class="mt-10 ">
                    <div class="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8 mb-4 text-amber-500">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                        <h2 class="font-bold">5. Autres informations</h2>
                        <p class="my-1 mb-0 text-sm text-gray-600">Parlez-nous brièvement de vous et soyez sincère.</p>
                    </div>
                    <div class="">
                        <div class="mb-4 ">
                            <div class="border-b-black border-spacing-5">
                                <div class="mb-3 2">
                                    <label class="my-[1rem]">Comment avez-vous connu HIGHFIVE University ?<span
                                            class="text-red-500">*</span></label>
                                    <select placeholder="Choisissez votre réponse..."
                                        class="w-full text-black my-[.5rem] border p-3 rounded-lg" required
                                        v-model="know_us">
                                        <option value=""></option>
                                        <option value="Presse">Presse</option>
                                        <option value="Internet">Internet</option>
                                        <option value="Par un ami">Un ami, une connaissance, un alumni...</option>
                                    </select>
                                    
                                    <div  v-if="know_us === 'Par un ami'">
                                        <label for="source_name">Quel est le nom de cet ami ? <span
                                            class="text-red-500">*</span></label>
                                    <input type="text"  placeholder="Quel est le nom de cet ami ?" class="  w-full my-[.5rem] border p-3 rounded-lg text-gray-600" v-model="source_name" name="source_name">
                                        
                                    </div>

                                    
                                    <div class="flex mb-3 2">
                                        <input type="checkbox" class="p-3 mx-2 text-gray-600 border rounded-lg "
                                            name="rules" required v-model="rules">
                                        <span>J'accepte que les informations saisies soient utilisées dans le cadre du
                                            déroulement de ce concours</span>
                                    </div>
                                    <div class="flex mb-3 2">
                                        <input type="checkbox" class="p-3 mx-2 text-gray-600 border rounded-lg "
                                            name="consent" required v-model="consent">
                                        <span>J'ai pris connaissance que le process d'admission peut ne pas aller
                                            jusqu'au
                                            bout
                                            si je ne franchis pas avec succès l'une des étapes du process (les droits
                                            d'inscription de 10 000 FCFA ne sont pas remboursés).</span>
                                    </div>
                                    <div class="">
                                        <button type="submit"
                                            class="px-4 py-2 font-bold text-white rounded-md bg-amber-500 hover:bg-yellow-600">Envoyer
                                            ma candidature</button>
                                        <p class="my-1 mb-0 text-sm italic text-black">NB: Avant de cliquer sur ENVOYER,
                                            veuillez vérifier que tous les champs obligatoires sont effectivement
                                            remplis.
                                            Les
                                            10 000 FCFA sont payables lors de votre préparation à la 1ère étape du
                                            concours
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>


    <!-- Info student  -->
    <infostudent />
    <!-- Footer -->
    <Pagefooter />
</template>

<script setup>
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';
import Swal from 'sweetalert2';

import { onMounted } from 'vue';
import { ref } from 'vue';
// const message = ref('');
const civility = ref('');
const first_name = ref('');
const last_name = ref('');
const birth = ref('');
const nationality = ref('');
const identity_document = ref('');
const document_number = ref('');
const email = ref('');
const address = ref('');
const city = ref('');
const district = ref('');
const phone = ref('');
const phone2 = ref('');
const study_level = ref('');
const year_graduated = ref('');
const university = ref('');
const graduated_name = ref('');
const actual_situation = ref('');
const english_level = ref('');
const why_us = ref('');
const your_expectations = ref('');
const just_imagin = ref('');
const your_futur = ref('');
const know_us = ref('');
const rules = ref('');
const consent = ref('');
const source_name = ref('');




async function sendData() {
    let data =
    {
        civility: civility.value,
        first_name: first_name.value,
        last_name: last_name.value,
        birth: birth.value,
        nationality: nationality.value,
        identity_document: identity_document.value,
        document_number: document_number.value,
        email: email.value,
        address: address.value,
        city: city.value,
        district: district.value,
        phone: phone.value,
        phone2: phone2.value,
        study_level: study_level.value,
        year_graduated: year_graduated.value,
        university: university.value,
        graduated_name: graduated_name.value,
        actual_situation: actual_situation.value,
        english_level: english_level.value,
        why_us: why_us.value,
        your_expectations: your_expectations.value,
        just_imagin: just_imagin.value,
        your_futur: your_futur.value,
        know_us: know_us.value,
        rules: rules.value,
        consent: consent.value,
        source_name : source_name.value
    }

    try {
        // let response = await fetch(`http://localhost/highfive_site/backend/route.php?goto=candidate&target=candidate_save`, {
        let response = await fetch(`/backend/route.php?goto=candidate&target=candidate_save`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        let responseData = await response.json();

        if (responseData.code == 409) {
            Swal.fire({
                icon: "error",
                title: "Erreur!",
                text: "Ce email a déjà été utilisé pour une candidature",
            });
        } else if (responseData.code == 200) {
            Swal.fire({
                title: "Impéccable!",
                text: "Votre candidature a été enregistrée avec succès. \n \n Un message de confirmation vous sera envoyé par mail après étude de votre candidature.\n \n Merci !",
                icon: "success"
            });

            // Réinitialiser les valeurs du formulaire
            civility.value = "";
            first_name.value = "";
            last_name.value = "";
            birth.value = "";
            nationality.value = "";
            identity_document.value = "";
            document_number.value = "";
            email.value = "";
            address.value = "";
            city.value = "";
            district.value = "";
            phone.value = "";
            phone2.value = "";
            study_level.value = "";
            year_graduated.value = "";
            university.value = "";
            graduated_name.value = "";
            actual_situation.value = "";
            english_level.value = "";
            why_us.value = "";
            your_expectations.value = "";
            just_imagin.value = "";
            your_futur.value = "";
            know_us.value = "";
            rules.value = "";
            consent.value = "";
            source_name.value = "";
        }
    } catch (error) {
        console.error('Erreur lors de la requête:', error);
        Swal.fire({
            icon: "error",
            title: "Erreur!",
            text: "Une erreur s'est produite lors de l'envoi de votre candidature. Veuillez réessayer plus tard.",
        });
        
        // Swal.fire({
        //         title: "Impéccable!",
        //         text: "Votre candidature a été enregistrée avec succès. \n \n Un message de confirmation vous sera envoyé par mail après étude de votre candidature.\n \n Merci !",
        //         icon: "success"
        //     });
    }
    // Réinitialiser les valeurs du formulaire
    civility.value = "";
    first_name.value = "";
    last_name.value = "";
    birth.value = "";
    nationality.value = "";
    identity_document.value = "";
    document_number.value = "";
    email.value = "";
    address.value = "";
    city.value = "";
    district.value = "";
    phone.value = "";
    phone2.value = "";
    study_level.value = "";
    year_graduated.value = "";
    university.value = "";
    graduated_name.value = "";
    actual_situation.value = "";
    english_level.value = "";
    why_us.value = "";
    your_expectations.value = "";
    just_imagin.value = "";
    your_futur.value = "";
    know_us.value = "";
    rules.value = "";
    consent.value = "";
    source_name.value = "";
}




const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
    scrollToTop();
});

const scrollToTop = () => {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

    }
}
</script>
