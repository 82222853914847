<script setup>
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import { onMounted, ref } from 'vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';

const componentRef = ref(null);

const scrollToTop = () => {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

    }
}

const debutInscription = ref('');

const getDate = () => {
    // fetch(`http://localhost/highfive_site/backend/route.php?goto=infos`)
    fetch(`/backend/route.php?goto=infos`)
        .then(response => response.json())
        .then(response => {
            // Options pour le format de date en français
            const options = { day: 'numeric', month: 'long', year: 'numeric' };

            debutInscription.value = response.depot_dossier ? new Date(response.depot_dossier).toLocaleDateString('fr-FR', options) : "date à venir";
        })
        .catch(error => {
            console.error('Erreur lors de la récupération des données :', error);
        });
};

onMounted(() => {
    getDate();
    // Faites défiler la vue en haut lorsque le composant est monté
    scrollToTop();
});

</script>


<template>
    <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />
    <!-- Join us -->
    <div ref="componentRef" class="py-12 bg-slate-100" id="profil-requis" data-spy="scroll">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="flex">
                <div class="lg:offset-1 lg:col-span-10 md:col-span-10 md:offset-1 col-span-12 mb-6">
                    <span class="uppercase text-yellow-500 font-semibold tracking-md">Nous rejoindre</span>
                    <h2 class="text-4xl mb-3 font-bold mt-3">Profil requis.</h2>
                    <p class="text-xl text-gray-600 my-4">
                        Pour intégrer notre première promotion, nul besoin d’être un as du codage. Notre travail consiste
                        avant tout à découvrir qui vous êtes et si vous êtes sélectionnés, à vous transmettre les
                        connaissances techniques les plus récentes pour exceller dans le métier de développeur codeur. Que
                        diriez-vous de savoir si vous êtes éligible ?
                    </p>
                </div>
            </div>
            <div class="grid ">
                <div class="flex flex-wrap gap-4 justify-center">
                    <div class="bg-white shadow-md p-4 rounded-lg mb-4 w-full">
                        <div class="mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-mortarboard text-yellow-500" viewBox="0 0 16 16">
                                <path
                                    d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z" />
                                <path
                                    d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z" />
                            </svg>
                        </div>
                        <h3 class="mb-2 font-bold">Niveau Bac+2 minimum</h3>
                        <p class="mb-0">
                            Disposer d’un niveau Bac+2, toutes séries confondues.
                        </p>
                    </div>
                    <div class="bg-white shadow-md p-4 rounded-lg mb-4 w-full">
                        <div class="mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-chat-dots text-yellow-500" viewBox="0 0 16 16">
                                <path
                                    d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                <path
                                    d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                            </svg>
                        </div>
                        <h3 class="mb-2 font-bold">Basic knowledge of English</h3>
                        <p class="mb-0">
                            Would be an asset.
                        </p>
                    </div>
                </div>
                <div class="flex flex-wrap gap-4 justify-center">
                    <div class="bg-white shadow-md p-4 rounded-lg mb-4 w-full">
                        <div class="mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-code-slash text-yellow-500" viewBox="0 0 16 16">
                                <path
                                    d="M10.478 1.647a.5.5 0 1 0-.956-.294l-4 13a.5.5 0 0 0 .956.294l4-13zM4.854 4.146a.5.5 0 0 1 0 .708L1.707 8l3.147 3.146a.5.5 0 0 1-.708.708l-3.5-3.5a.5.5 0 0 1 0-.708l3.5-3.5a.5.5 0 0 1 .708 0zm6.292 0a.5.5 0 0 0 0 .708L14.293 8l-3.147 3.146a.5.5 0 0 0 .708.708l3.5-3.5a.5.5 0 0 0 0-.708l-3.5-3.5a.5.5 0 0 0-.708 0z" />
                            </svg>
                        </div>
                        <h3 class="mb-2 font-bold">Aucune connaissances spécifiques en codage</h3>
                        <p class="mb-3">
                            C'est vraiment sérieux !
                        </p>
                    </div>
                    <div class="bg-white shadow-md p-4 rounded-lg mb-4 w-full">
                        <div class="mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-keyboard text-yellow-500" viewBox="0 0 16 16">
                                <path
                                    d="M14 5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12zM2 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2z" />
                                <path
                                    d="M13 10.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5 0A.25.25 0 0 1 8.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 8.75v-.5zm2 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-.5zm1 2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5-2A.25.25 0 0 1 6.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 6 8.75v-.5zm-2 0A.25.25 0 0 1 4.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 8.75v-.5zm-2 0A.25.25 0 0 1 2.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 8.75v-.5zm11-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0A.25.25 0 0 1 9.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 9 6.75v-.5zm-2 0A.25.25 0 0 1 7.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 7 6.75v-.5zm-2 0A.25.25 0 0 1 5.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 5 6.75v-.5zm-3 0A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 6.75v-.5zm0 4a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm2 0a.25.25 0 0 1 .25-.25h5.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-5.5a.25.25 0 0 1-.25-.25v-.5z" />
                            </svg>
                        </div>
                        <h3 class="mb-2 font-bold">Avoir un minimum de doigté sur le clavier</h3>
                        <p class="mb-0">
                            Si vous rencontrez de grandes difficultés à faire une simple saisie à vitesse relativement
                            moyenne, ce sera difficile pour vous, on ne vous le cache pas.
                        </p>
                    </div>
                </div>
                <div class="flex flex-wrap gap-4 justify-center">
                    <div class="bg-white shadow-md p-4 rounded-lg mb-4 w-full">
                        <div class="mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-laptop text-yellow-500" viewBox="0 0 16 16">
                                <path
                                    d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                            </svg>
                        </div>
                        <h3 class="mb-2 font-bold">Nul besoin d’avoir un ordinateur</h3>
                        <p class="mb-0">
                            HIGHFIVE University met au service des étudiants sélectionnés tout le matériel logistique
                            nécessaire pour l’apprentissage lors de vos cours.
                        </p>
                    </div>
                    <div class="bg-white shadow-md p-4 rounded-lg mb-4 w-full">
                        <div class="mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-hand-thumbs-up text-yellow-500" viewBox="0 0 16 16">
                                <path
                                    d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                            </svg>
                        </div>
                        <h3 class="mb-2 font-bold">Un maximum de motivation</h3>
                        <p class="mb-0">
                            Pour tenir dans cette formation, il vous faudra beaucoup de motivation.
                        </p>
                    </div>
                </div>
            </div>
            <div class="container mx-auto">
                <p class="text-xl text-gray-600 my-4">
                    HIGHFIVE University se donne pour mission de faire de vous des développeurs de grande qualité dont les
                    entreprises «raffolent».
                </p>
                <p class="text-xl text-gray-600 my-4">
                    À la question des frais de scolarité. Il n’y en a pas, seuls 10 000 FCFA de frais de dossiers vous
                    seront demandés au moment du concours; pourquoi ?
                    <router-link to="/">
                        <a class=" text-yellow-500 ">Cliquez ici</a>
                    </router-link>
                </p>
            </div>
        </div>
    </div>

    <!-- Admission -->
    <div class="py-12 bg-white" id="procedure-admission" data-spy="scroll">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <h2 class="text-4xl mb-3 font-bold mt-3">Procédure d'admission.</h2>
            <p class="text-xl text-gray-600 my-4 mb-3">
                Nous ne recherchons pas des compétences techniques mais des prédispositions pour intégrer les connaissances
                nécessaires à ce métier. Nous cherchons aussi à nous assurer que le métier de développeur vous correspond
                bien… dans votre propre intérêt bien sûr car nous voulons votre succès, dans l’intérêt de votre promotion
                pour que le niveau soit homogène et dans l’intérêt des entreprises afin de mettre à leur disposition des
                développeurs de qualité. Nous recherchons donc également des traits de personnalité dont nous savons qu’ils
                seront nécessaires à votre succès sur le chemin que vous empruntez.
            </p>
            <p class="text-xl text-gray-600 my-4 mb-3">
                Avez-vous le minimum de talent et de volonté qu’il faut ? Etes-vous vraiment fait pour suivre cette
                formation et exercer ce métier ? Intéressés ? Convaincus ? Vous souhaitez postuler? C’est le moment de
                découvrir comment gravir les premières marches de l’escalier.
            </p>
            <p class="text-xl text-gray-600 my-4 mb-3">
                Notre prochaine session de candidatures avec préinscription au Bénin (Cotonou) :
                <span class="text-yellow-500">{{ debutInscription }}.</span>
            </p>
        </div>
    </div>

    <!-- Process -->
    <div class="py-12 bg-slate-100">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="flex text-center">
                <h2 class="text-4xl mb-3 font-bold mt-3">Nous procédons en 3 étapes pour sélectionner des candidats qui
                    s’épanouiront à nos côtés.</h2>
            </div>
            <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
                <div class="col-md-4 col-12">
                    <div class="text-center">
                        <div
                            class="mx-auto w-12 h-12 border-2 border-primary rounded-full flex items-center border-yellow-500 text-yellow-500 justify-center shadow-md my-[1rem]">
                            1</div>
                        <h3 class="mb-2 font-bold">Candidature</h3>
                        <p class="mb-0 px-4">
                            Dossier de candidature à remplir en ligne.

                            <router-link to="/Candidature">
                                <a class=" text-yellow-500">Cliquez ici</a>
                            </router-link> pour nous en dire d'avantage sur vos
                            motivations et vous-même.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="text-center ">
                        <div
                            class="mx-auto w-12 h-12 border-2 border-primary rounded-full flex items-center border-yellow-500 text-yellow-500 justify-center shadow-md my-[1rem]">
                            2</div>
                        <h3 class="mb-2 font-bold">Test psychotechnique et de logique</h3>
                        <p class="mt-5 px-2">
                            À réaliser au sein de nos locaux si votre candidature est validé.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="text-center ">
                        <div
                            class="mx-auto w-12 h-12 border-2 border-primary rounded-full flex items-center border-yellow-500 text-yellow-500 justify-center shadow-md my-[1rem]">
                            3</div>
                        <h3 class="mb-2 font-bold">Entretiens</h3>
                        <p class="mt-5 px-3">
                            Entretiens collectif et individuel pour évaluer votre dynamique.
                        </p>
                    </div>
                </div>
            </div>
            <div class="md:col-span-3 mt-5 text-center">
                <span class="text-sm">
                    <i>Merci de noter que le process d’admission peut ne pas aller jusqu’au bout si une des étapes n’est pas
                        franchie avec succès.</i>
                </span>
            </div>
            <div class="col-span-1 md:col-span-3 mt-8 text-center">

                <router-link to="/Candidature">
                    <a class="px-8 py-3 bg-primary bg-yellow-500 text-white rounded-lg">Déposer ma
                        candidature</a>
                </router-link>
            </div>
        </div>
    </div>

    <!-- Entry -->
    <div class="py-12 bg-white">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <h2 class="text-4xl mb-3 font-bold mt-3">L’entrée à HIGHFIVE University.</h2>
            <p class="text-xl text-gray-600 my-4 mb-3">
                Les candidats retenus reçoivent sous 24h un e-mail qui notifie leur admission ainsi que leur convocation
                pour débuter effectivement les cours. Vous avez passé avec succès ces 3 étapes ? Alors c’est bon… Give me
                five! Nous vous retrouverons pour démarrer concours.
            </p>
            
            <p class="text-xl text-gray-600 my-4 mb-3">
                À très vite ! <i class="bi bi-hand-thumbs-up"></i>
            </p>
        </div>
    </div>


    <!-- Info student  -->
    <infostudent />
    <!-- Footer -->
    <pagefooter />
</template>
