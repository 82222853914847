import { createApp } from 'vue';
import pinia from 'pinia';
import router from './router';
import App from './App.vue';

import './main.css'; // Import your Tailwind CSS file

const app = createApp(App);
app.use(pinia);
app.use(router);
app.mount('#app');