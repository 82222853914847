<script setup>
import Pagefooter from "@/components/Contents/FooterComponent.vue";
import infostudent from "@/components/Contents/InfoStudentComponent.vue";
// import { format, parse } from 'date-fns';
import { onMounted, onUpdated, ref } from "vue";



const reunion_infos = ref([]);

const formatDate = (date) => {
  const inputDate = new Date(date);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedDate = inputDate.toLocaleDateString("fr-FR", options);
  return formattedDate;
};

const formatTime = (time) => {
  const inputDateTime = new Date(time);  // Utilisez le paramètre 'time' ici
  const options = { hour: "numeric", minute: "numeric", hour12: false };

  const formattedTime = inputDateTime.toLocaleTimeString("fr-FR", options);

  // Extracting hours and minutes
  const [hours, minutes] = formattedTime.split(":");

  const finalFormattedTime = `${hours}h${minutes}`;
  return finalFormattedTime;
};

async function getAllWebinaire() {
  // fetch(
    // `http://localhost/highfive_site/backend/route.php?goto=getAllwebinaires&target=getAllWebinaire`,

  fetch(
    `/backend/route.php?goto=getAllwebinaires&target=getAllWebinaire`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  )
    .then((response) => response.json())
    .then((response) => {

      reunion_infos.value = response;
      console.log("reunion_info", reunion_infos.value);
    });
}

onUpdated(() => { });
onMounted(() => {
  getAllWebinaire();
});


const loading = ref(false);
const confirmationMessage = ref('');
const errorMessage = ref('');
const idWeb = ref('');
const nom = ref('');
const prenom = ref('');
const email = ref('');
const telephone = ref('');
// const message = ref('');
const hidden = ref(false);
const sessions = ref('--');

const recupId = (id) => {
  const selectedReunion = reunion_infos.value.find((el) => el.id === id);
  
  idWeb.value = id;
  hidden.value = true;
  if (selectedReunion) {
    sessions.value = selectedReunion.date_reunion;
  }
};

const hiden = () => {
  nom.value = '';
  prenom.value = '';
  email.value = '';
  telephone.value = '';
  hidden.value = false;
};

const inscription = async () => {
  loading.value = true; // Activer le loader
  try {
    const data = {
      reunion_infos_id: idWeb.value,
      nom: nom.value,
      prenom: prenom.value,
      email: email.value,
      telephone: telephone.value,
      sessions: sessions.value,
    };

    fetch('http://localhost/highfive_site/backend/route.php?goto=webinaires&target=webinaire_save', {
    // fetch('/backend/route.php?goto=webinaires&target=webinaire_save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => JSON.stringify(res))
      .then((response) => {
        if (response) {
      confirmationMessage.value = 'Formulaire soumis avec succès!';
      // Réinitialiser les champs
      hiden();
    } else {
      errorMessage.value = 'Une erreur s\'est produite. Veuillez réessayer.';
    }
      });
  } catch (error) {
    errorMessage.value = 'Une erreur s\'est produite. Veuillez réessayer.';
    console.error('Erreur', error);
  } finally {
    loading.value = false; // Désactiver le loader
  }
};

</script>

<template>
  <div class="mx-10">
    <div v-if="loading" class="fixed inset-0 z-50 flex items-center justify-center">
    <!-- Ajouter ici votre spinner ou loader -->
    <div class="loader"></div>
  </div>

  <div v-if="confirmationMessage" class="fixed inset-0 z-50 flex items-center justify-center">
    <!-- Pop-up de confirmation -->
    <div class="p-5 text-center bg-green-500 rounded-md">
      <p class="mb-2 text-white">{{ confirmationMessage }} </p>
      <button class="px-2 bg-white rounded-lg mt -5 hover:bg-slate-100"  @click="confirmationMessage = ''">Fermer</button>
    </div>
  </div>

  <div v-if="errorMessage" class="fixed inset-0 z-50 flex items-center justify-center">
    <!-- Pop-up d'erreur -->
    <div class="p-5 text-white bg-red-500 rounded-md">
      <p>{{ errorMessage }}</p>
      <button class="bg-white mt -5" @click="errorMessage = ''">Fermer</button>
    </div>
  </div>
    <h1 class="mt-20 text-3xl font-bold text-center">Séances d'informations en ligne</h1>

    <p class="mt-2 text-center">Rejoignez-nous pour une passionnante session d'informations en ligne ! Choisissez la plage
      horaire qui vous convient le mieux et inscrivez-vous dès maintenant pour une expérience enrichissante.</p>

    <div class="py-5 my-10">
      <!-- container liste webinaire -->
      <div class="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
        <div class="flex flex-col justify-between overflow-hidden text-center rounded shadow-lg "
          v-for="reunion_info in reunion_infos" :key="reunion_info.id">
          <img class="object-cover w-full h-60"
            :src="require(`../assets/images/` + reunion_info.img) || require('../assets/images/web1.webp')"
            alt="Sunset in the mountains" />

          <div class="py-4">
            <!-- <h2 class="text-xl font-bold text-gray-700 capitalize">
              {{ reunion_info.theme_reunion }}
            </h2>
            <p class="">
              <span class="">
                {{ reunion_info.theme_reunion }}
              </span> 
            </p>-->

            <p class="text-lg font-bold text-gray-700 ">
              {{ formatDate(reunion_info.date_reunion) }}
              à {{ formatTime(reunion_info.date_reunion) }}
            </p>

          </div>
          <div class="">
            <span
              class="inline-block px-4 py-2 mb-5 mr-2 text-sm font-semibold text-white rounded-lg bg-amber-500 hover:cursor-pointer"
              @click="recupId(reunion_info.id)">S'inscrire à cette séance</span>
          </div>
        </div>
      </div>
      <div v-if="hidden" class="fixed inset-0 z-40 flex items-center justify-center">
        <div class="fixed inset-0 z-40 bg-black opacity-60"></div>
        <form class="z-50 p-5 mx-5 bg-white rounded-md md:w-96 w-80" @submit.prevent="inscription()">

          <h1 class="flex flex-col gap-5 mb-2 text-2xl font-extrabold text-center text-gray-700">
            <!-- {{ reunion_infos.find((el) => el.id === idWeb).theme_reunion }} -->
            Séance du {{ formatDate(reunion_infos.find((el) => el.id === idWeb).date_reunion) }} à {{
              formatTime(reunion_infos.find((el) => el.id === idWeb).date_reunion) }}
          </h1>
          <div class="flex flex-col gap-5">

            <input class="p-2 pl-3 border bg-slate-50 border-slate-200 rouded-lg focus:ring focus:ring-amber-500"
              type="text" placeholder="Nom" v-model="nom" required />

            <input class="p-2 pl-3 border bg-slate-50 border-slate-200 rouded-lg focus:ring focus:ring-amber-500"
              type="text" placeholder="Prénoms" v-model="prenom" required />

            <input class="p-2 pl-3 border bg-slate-50 border-slate-200 rouded-lg focus:ring focus:ring-amber-500"
              type="email" placeholder="Email" v-model="email" required />

            <input class="p-2 pl-3 border bg-slate-50 border-slate-200 rouded-lg focus:ring focus:ring-amber-500"
              type="text" placeholder="Téléphone (+229xxxxxxxx)" v-model="telephone" required />


          </div>

          <div class="flex justify-between mt-5">
            <button type="submit" class="flex px-4 py-2 text-white rounded-lg bg-amber-500">
              <!-- <div class="loader " title="1">                
              </div> -->
              Soumettre
            </button>
            <button type="button" class="px-4 py-2 text-white rounded-lg bg-amber-500" @click="hiden()">
              Fermer
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Info student  -->
  <infostudent />
  <!-- Footer -->
  <pagefooter />
</template>




<style  scoped>
.loader {
  width: 25px;
  height: 25px;
  border-top: 2px solid white;
  border-radius: 50%;
}

/*
  Set the color of the icon
*/
svg path,
svg rect {
  fill: #FF6700;
}
</style>
