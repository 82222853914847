<script setup>
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';
import { onMounted, ref } from 'vue';

const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
    scrollToTop();
});

const scrollToTop =() => {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

    }
}
</script>

<script>
export default {
    data() {
        return {
            bootcamps: [
                {
                    contenus: 'HTML & CSS',
                    nbreHeurs: '220',
                    pourcentages: '28%',
                },
                {
                    contenus: 'JAVASCRIPT',
                    nbreHeurs: '178',
                    pourcentages: '22%',
                },
                {
                    contenus: 'SQL',
                    nbreHeurs: '48',
                    pourcentages: '6%',
                },
                {
                    contenus: 'REACT',
                    nbreHeurs: '48',
                    pourcentages: '6%',
                },
                {
                    contenus: 'FLUTTER',
                    nbreHeurs: '40',
                    pourcentages: '5%',
                },
                {
                    contenus: 'WORDPRESS',
                    nbreHeurs: '16',
                    pourcentages: '2%',
                },
                {
                    contenus: 'SYNFONY & TWIG',
                    nbreHeurs: '40',
                    pourcentages: '5%',
                },
                {
                    contenus: 'PHP',
                    nbreHeurs: '110',
                    pourcentages: '14%',
                },
                {
                    contenus: 'JAVA',
                    nbreHeurs: '40',
                    pourcentages: '5%',
                },
                {
                    contenus: 'Gestion de Projet',
                    nbreHeurs: '40',
                    pourcentages: '5%',
                },
                {
                    contenus: 'Développement personnel & Soft Skills',
                    nbreHeurs: '28',
                    pourcentages: '4%',
                },
                // {
                //     contenus: 'Mise en pratique et contrôle des acquis',
                //     nbreHeurs: '120',
                //     pourcentages: '15%',
                // },
            ],

            alternances: [
                {
                    contenus: 'JAVA',
                    nbreHeurs: '40',
                    pourcentages: '6%',
                },
                {
                    contenus: 'SPRING FRAMEWORK',
                    nbreHeurs: '120',
                    pourcentages: '19%',
                },
                {
                    contenus: 'ANGULAR',
                    nbreHeurs: '48',
                    pourcentages: '8%',
                },
                {
                    contenus: 'FLUTTER',
                    nbreHeurs: '44',
                    pourcentages: '7%',
                },
                {
                    contenus: 'NODE JS',
                    nbreHeurs: '96',
                    pourcentages: '15%',
                },
                {
                    contenus: 'VUE NATIVE',
                    nbreHeurs: '48',
                    pourcentages: '8%',
                },
                {
                    contenus: 'PYTHON',
                    nbreHeurs: '80',
                    pourcentages: '13%',
                },
                {
                    contenus: 'DATABASE NOSQL',
                    nbreHeurs: '64',
                    pourcentages: '10%',
                },
                {
                    contenus: 'SYMFONY',
                    nbreHeurs: '12',
                    pourcentages: '2%',
                },
                {
                    contenus: 'Cyber sécurité',
                    nbreHeurs: '40',
                    pourcentages: '69%',
                },
                {
                    contenus: 'Développement personnel & Soft Skills',
                    nbreHeurs: '42',
                    pourcentages: '7%',
                },
                // {
                //     contenus: 'Mise en pratique et contrôle des acquis',
                //     nbreHeurs: '120',
                //     pourcentages: '15%',
                // },
            ]
        }
    }

}
</script>
<template >
    <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />
    <div class="bg-slate-100 mx-auto " ref="componentRef">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <div class="mb-6">
                        <span class="uppercase font-bold text-amber-500">Formation</span>
                        <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4"><svg
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-12 h-12">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                            </svg> Une pédagogie innovante.</h2>
                        <p class="text-xl text-gray-600 my-4">
                            HIGHFIVE University base ses formations sur des contenus innovants et éprouvés, équivalents à
                            ceux d’un BTS, d’une licence et d’un master français. La pédagogie et les rythmes sont soutenus,
                            comme dans une Business School américaine, et
                            l’exigence envers les étudiants est élevée. L’ambition du groupe est de créer l’élite du codage
                            africain, avec des étudiants engagés et travailleurs, autour d’une pédagogie active et d’un
                            contrôle des acquis permanent. Ce contrôle des acquis peut conduire, en cas d’échecs répétés, au
                            renvoi
                            de l’étudiant qui aura insuffisamment travaillé.
                        </p>
                        <p class="text-xl text-gray-600 my-4">
                            HIGHFIVE University intègre également le développement de compétences comportementales
                            (soft skills) qui complètent les compétences techniques des développeurs informatiques.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white mx-auto my-10">
        <!-- Staff -->
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4"><svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-12 h-12">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                        </svg>L'équipe pédagogique</h2>
                    <p class="text-xl text-gray-600 my-4">
                        Nos premiers enseignants ont été sélectionnés et recrutés par Le Groupe Vipp, et ont renforcé leur
                        compétence par une formation approfondie de plusieurs mois en France, tant sur les modules
                        techniques que sur la pédagogie. Ils ont eu pour première mission d’élaborer, avec des recruteurs
                        internationaux, les contenus pédagogiques de HIGHFIVE University.<br />
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        Tous nos formateurs sont aujourd’hui recrutés et formés directement par HIGHFIVE University et
                        bénéficient d’une double compétence : technique sur les métiers du codage et opérationnelle par
                        rapport au type de sujets à adresser au sein des organisations (tous nos formateurs ont exercé
                        auparavant dans des entreprises privées au sein de leur SI).<br />
                        A ce titre, les programmes de formation visent à former nos étudiants de
                        manière extrêmement opérationnelle, avec 90% d’applications concrètes et 10% d’apports théoriques).
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        Le ratio de l’encadrement en cours est de 1 enseignant pour 15 étudiants, garantissant un suivi
                        personnalisé de tous les étudiants, sur l’ensemble de leur cursus.
                    </p>

                    <!-- </div> -->
                </div>
            </div>
        </div>

    </div>
    <div class="bg-slate-100 mx-auto my-10">
        <!-- Model -->
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4"><svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-11 h-11">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                        </svg>Le modèle pédagogique.</h2>
                    <p class="text-xl text-gray-600 my-4">
                        Les programmes de formation visent à former nos étudiants de manière extrêmement opérationnelle,
                        avec 90% d’applications concrètes et 10% d’apports théoriques. HIGHFIVE University a également
                        intégré la nécessité de développer des compétences comportementales (soft skills) qui complètent les
                        compétences techniques du codage.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        Le cursus se répartit sur 24 mois, pour assimiler les acquis de manière durable et être très
                        rapidement opérationnel.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        Ces 24 mois sont scindés en 2 périodes.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold italic text-2xl">Période 1 :</span> 6 mois de formation accélérée, en mode bootcamp (type entrainement militaire) - <span class="font-bold italic">Objectif :</span>
                         Atteindre le niveau d’un développeur junior en très peu de temps.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        978 heures de cours, à raison de 40 h de cours par semaine. Mise a disposition des infrastructures
                        et du matériel en dehors des horaires de cours pour s'entrainer. Contrôle continu et examens chaque
                        semaine. Les résultats sont susceptibles de remettre en cause la scolarité de l’étudiant.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        Formations intensives sur des temps courts basées essentiellement sur la pratique pour répondre aux
                        demandes et aux exigences du marché. L’apprentissage est basé sur le développement de sites internet
                        interactifs qui utilisent les technologies HTML, CSS et JavaScript. Il est prévu dans le parcours
                        plusieurs modules de règles professionnelles qui seront programmés sur plusieurs semaines.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold flex text-black items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                class="w-6 h-6 text-yellow-500">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>S1 à la S5 :</span> Intégration des étudiants à l'apprentissage de base et
                        d'approfondissement des langages HTML et CSS.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        Les étudiants seront amenés à assimiler les bases du HTML, la gestion des fichiers sur le web ainsi
                        que l'anatomie d'une page HTML. Ils seront également amenés à maitriser la mise en page responsive
                        avec les flexbox CSS, animations et transitions ainsi que le CSS Grid Layout.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold flex text-black gap-2 items-center"><svg xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                class="w-6 h-6 text-yellow-500">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>S6 à la S9 :</span> Les bases de JavaScript et Les fondements de la programmation : La
                        conception orientée objet sera dispensé durant ces 4 semaines.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold text-black flex items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                class="w-6 h-6 text-yellow-500">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>S10 à la S16 :</span> Maitrise des langages Hypertext Preprocessor, plus connu sous son
                        sigle PHP, CMS et Framework., et d’autres langages (voir rubrique langages informatiques). Un cours
                        sur la RGPD et sur les mentions légales sera également dispensé. Durée totale 7 semaines.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold text-black flex items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                class="w-6 h-6 text-yellow-500">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>S17 à S21 :</span> Travail en autonomie sur projets de validation du Bootcamp, avec
                        supervision et encadrement de notre équipe de formateurs.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold italic text-2xl">Période 2 Alternance :</span> 634 heures de cours (+ 1632 h de projet pour l’entreprise).
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        17 mois chez HIGHFIVE University : Projet pour le compte d'une entreprise, alternant pratiques et
                        consolidation des acquis théoriques.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        Les Projets d'entreprises seront réalisés dans nos locaux et sur nos infrastructures, sous la
                        supervision du Chef de Projet de l’entreprise cooptant l'étudiant.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold text-black flex items-center gap-2"> <span><svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                    class="h-6 w-5 text-yellow-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                </svg></span> 4 jours par semaine</span> sont consacrés au projet à réaliser pour le compte
                        de l'entreprise.
                    </p>
                    <p class="text-xl text-gray-600 my-4">
                        <span class="font-bold text-black flex items-center gap-2"><span><svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                    class="h-6 w-5 text-yellow-500">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                </svg></span> 2 jours par semaine</span> sont consacrés à la poursuite des cours, pour
                        ancrer les compétences acquises sur 5 mois et les développer.
                    </p>
                </div>
            </div>
        </div>



    </div>
    <!-- Journée type section -->
    <div class="bg-white mx-auto my-10" id="journee-type">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="container mx-auto">
                <h2 class=" flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Journée type au sein de HIGHFIVE
                    University.</h2>

                <div class="flex flex-wrap">
                    <!-- <div class=""> -->
                    <div class="m-2 mb-[2rem]">
                        <p class="flex hover:text-yellow-500 text-2xl font-bold">
                            <span class="text-yellow-600 flex"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-9">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg></span>Journée type en bootcamp (entrainement militaire) 40h :
                        </p>
                        <p class="text-xl text-gray-600 my-1">
                            Pendant la période d’enseignement en bootcamp, les cours ont lieu du lundi au vendredi à hauteur
                            de 8h par jour.
                        </p>
                    </div>
                    <div class="m-2 mb-[2rem]">
                        <p class="flex hover:text-yellow-500 text-2xl font-bold">
                            <span class="text-yellow-600 flex"><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-9">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg></span>Journée type en alternance 40h/sem :
                        </p>
                        <p class="text-xl text-gray-600 my-2">
                            Pendant la période d’alternance, votre présence est requise 7h par jour les lundis,
                            mardis,jeudis et vendredis et 8h les mercredis, sans oublier 4h les samedis.
                        </p>
                    </div>
                    <!-- </div> -->

                    <!-- Day type information section -->
                    <div class="">
                        <p class="text-xl text-gray-600 my-2">
                            À noter que nous mettons les salles de cours à votre disposition jusqu'à 20h pour vos exercices,
                            vos
                            séances de révision et vos échanges entre étudiants, en prévision de vos contrôles de
                            connaissance, sur l’ensemble des 24 mois que dure votre formation. Les contrôles de
                            connaissances se font n’importe quel jour de la semaine en fonction de notre calendrier de cours
                            et tiennent compte du fait
                            qu’une session est terminée.
                        </p>
                        <p class="text-xl text-gray-600 my-2">
                            En outre, les étudiants bénéficient également de notre centre documentaire mis à disposition des
                            salariés du Groupe. Il est situé dans nos locaux, à quelques mètres des salles de cours, et
                            s’enrichit régulièrement de nouveaux ouvrages, pour être au cœur des innovations.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-slate-100 mx-auto py-5">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="container mx-auto">
                <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Contenu pédagogique</h2>
                <p class="text-xl text-gray-600 my-2">
                    Le programme pédagogique (plus de 1900 heures de cours avec applications concrètes sur 2 ans) a pour
                    objectif de développer deux types de compétences :
                </p>
                <ul class="space-y-6 text-xl">
                    <li class="mt-5 mb-3">
                        <span class="mt-5">
                            <span class="flex gap-2 text-xl text-gray-600 my-2"><svg fill="#f4a62a"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="w-14 h-14 text-amber-500 bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm3.354-8.354a.5.5 0 0 0-.708-.708L7 8.793 5.354 7.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l4-4z" />
                                </svg>Les compétences techniques qui portent pour l’essentiel sur l’acquisition de
                                informatique mais également sur des compétences plus étendues comme la gestion de projets ou
                                la sécurité informatique. Ces deux derniers points ne doivent pas en effet rester du domaine
                                de quelques personnes dans une SI, mais être l’affaire de tous.</span>
                        </span>
                    </li>
                    <li class="mb-8">
                        <span class="flex gap-2 text-xl text-gray-600 my-2"><svg fill="#f4a62a"
                                xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 text-amber-500 bi bi-check-circle-fill"
                                viewBox="0 0 16 16">
                                <path
                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm3.354-8.354a.5.5 0 0 0-.708-.708L7 8.793 5.354 7.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l4-4z" />
                            </svg> Les compétences comportementales (soft skills). Ce type de compétence s’avère
                            indispensable pour passer d’un « développeur ordinaire » à « un développeur exceptionnel ». Nous
                            avons identifié une dizaine de compétences que nous souhaitons développer chez nos stagiaires,
                            ce qui fera la différence quand ils seront en activité professionnelle.
                        </span>
                    </li>
                </ul>
            </div>
            <!-- </div> -->

            <div class="flex flex-wrap items-center mt-5">
                <div class="mb-4 px-5 w-full lg:w-6/12 lg:mx-auto md:w-6/12 md:mx-auto">
                    <div class="mb-4">
                        <p class="font-bold text-lg mb-9">Période 1 – Bootcamp : 808 heures de cours</p>
                        <div class="rounded-sm ">
                            <table class=" border-collapse w-full text-sm text-left ">
                                <thead class="text-xs uppercase bg-amber-500">
                                    <tr>
                                        <th scope="col" class=" border border-y-amber-500 px-2 py-3">Contenus</th>
                                        <th scope="col" class=" border border-y-amber-500 px-2 py-3">Nombre d'heures</th>
                                        <th scope="col" class=" border border-amber-500 px-2 py-3">Pourcentages d'heures
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contents, index) in bootcamps" :key="index" class="bg-white border-b ">
                                        <th scope="row" class=" border border-amber-500 px-2 py-2 ">
                                            {{ contents.contenus }}
                                        </th>
                                        <td class="border border-amber-500 px-2 py-2 text-end">{{ contents.nbreHeurs }}</td>
                                        <td class=" border border-amber-500 px-2 py-2 text-end">{{ contents.pourcentages }}
                                        </td>
                                    </tr>
                                    <tr class=" bg-amber-500 ">
                                        <th scope="row" class=" border border-y-amber-500 px-2 py-2 font-bold">TOTAL</th>
                                        <td class="border border-y-amber-500 px-2 py-4 text-end font-bold">808</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="mb-4 px-5 w-full lg:w-6/12 lg:mx-auto md:w-6/12 md:mx-auto">
                    <div class=" mb-4 ">
                        <p class="font-bold text-lg mb-2">Période 2 – Alternance : 634 heures de cours (+1632 h de projet
                            pour l’entreprise)</p>
                        <div class="rounded-sm ">
                            <table class=" border-collapse w-full text-sm text-left ">
                                <thead class="text-xs uppercase bg-amber-500">
                                    <tr>
                                        <th scope="col" class=" border border-y-amber-500 px-2 py-3">Contenus</th>
                                        <th scope="col" class=" border border-y-amber-500 px-2 py-3">Nombre d'heures</th>
                                        <th scope="col" class=" border border-amber-500 px-2 py-3">Pourcentages d'heures
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contents, index) in alternances" :key="index" class="bg-white border-b ">
                                        <th scope="row" class=" border border-amber-500 px-2 py-2 ">
                                            {{ contents.contenus }}
                                        </th>
                                        <td class="border border-amber-500 px-2 py-2 text-end">{{ contents.nbreHeurs }}</td>
                                        <td class=" border border-amber-500 px-2 py-2 text-end">{{ contents.pourcentages }}
                                        </td>
                                    </tr>
                                    <tr class=" bg-amber-500 ">
                                        <th scope="row" class=" border border-y-amber-500 px-2 py-2 font-bold">TOTAL</th>
                                        <td class="border border-y-amber-500 px-2 py-4 text-end font-bold">634</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-5 pb-10">
            <div class="bg-white mx-auto my-10">
                <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5 py-5">
                    <div class="lg:col-start-2 lg:col-span-10 md:col-start-2 md:col-span-10 col-span-12 mb-10">
                        <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Langages informatiques qui sont
                            développés dans la formation.</h2>
                        <p class="text-xl text-gray-600 mb-3">
                            Il existe des centaines de langages de programmation et nous n'avons pas vocation à vous faire
                            devenir des historiens des langages de codes mais de faire de vous des professionnels qui
                            connaitront une douzaine de logiciels qui couvrent en 2022 plus de 90 % des besoins des
                            entreprises.
                        </p>
                        <p class="text-xl text-gray-600 my-2">
                            HIGHFIVE University vous présente ici succinctement chacun de ceux que vous découvrirez.
                        </p>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-javascript.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">JavaScript</h4>
                                        <p class="mb-0 text-base">
                                            JavaScript est l’un des langages les plus utilisés dans le développement de
                                            sites web et d’applications. Il permet particulièrement d’ajouter de
                                            l’interactivité à des pages web afin de les rendre dynamiques. Utilisé sur
                                            environ 95% des sites web dans le monde, il a une portée extrêmement importante.
                                            Contrairement aux autres langages de programmation qui s’exécutent sur des
                                            serveurs, le JavaScript s’exécute directement sur notre Smartphone ou notre
                                            ordinateur. C’est ce langage qui permet d’animer, de faire apparaitre ou
                                            disparaitre les éléments d’une page web tels qu’une image, un menu vidéo, un
                                            titre… Il permet également de mettre à jour ces éléments sans pour autant
                                            recharger entièrement la page web. Très proche de la langue anglais, la syntaxe
                                            du JavaScript est assez simple et intuitive.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-java.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">Java</h4>
                                        <p class="mb-0 text-base">
                                            A l’origine, Java est qualifié de langage de programmation mais le langage a
                                            beaucoup évolué. Son évolution lui a permis d’être toujours au cœur de
                                            développement de softwares et d’applications multiplateformes contrairement au
                                            Javascript qui est un langage de script. Autrement dit, le Java permet de créer
                                            des applications exécutables sur un navigateur virtuel ou une machine tandis que
                                            le Javascript est un code qui s'exécute uniquement sur navigateur. Aujourd’hui,
                                            de nombreux services et applications dépendent de ce langage. Java est synonyme
                                            d’expertise dans le développement web, ce qui permet aux profils de se démarquer
                                            sur le marché du travail. Les entreprises spécialisées dans l’informatique y ont
                                            souvent recours.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-python.jpg" alt="" class="w-12 h-12">
                                    <div class="ml-3">
                                        <h4 class="mb-1">Python</h4>
                                        <p class="mb-0 text-base">
                                            Python est un langage de programmation côté-serveur(back-end) créé en 1991. Il
                                            permet de développer des applications web, des softwares ou d'automatiser des
                                            tâches. C'est un langage polyvalent qui peut aussi bien êre utilisé en
                                            éducation, qu’en ingénierie logicielle ou encore en développement web. Il est
                                            très utilisé dans les sciences notamment en Data Science. Python regroupe une
                                            grande communauté composée de web développeurs, de scientifiques et d’analystes.
                                            Il a pour librairie Tensorflow ou encore NumPy et Pandas qui sont des librairies
                                            Python populaires et très appréciées en Data sciences.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-php.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">PHP</h4>
                                        <p class="mb-0 text-base">
                                            PHP est un langage de programmation back-end créé en 1994. Il est utilisé pour
                                            développer des sites web dynamiques. Sa singularité est qu’il est très simple
                                            d’usage de par son intégration dans HTML. Il permet aussi un nombre important de
                                            fonctionnalités avancées pour les experts. Principalement conçu pour du langage
                                            de script côté serveur, PHP peut également être utilisé en langage de
                                            programmation en ligne de commande, c’est-à-dire sans l’aide d’un navigateur et
                                            d’un serveur web. Maitriser PHP offre de réelles opportunités sur le marché du
                                            travail et permet d’être web développeur full-stack ou développeur back-end.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-sql.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">SQL</h4>
                                        <p class="mb-0 text-base">
                                            SQL (Structured Query Language) est un langage informatique nommé en français
                                            “langage de requête structurée”. C’est l’un des langages les plus utilisés dans
                                            son domaine car il permet de lire et d’exploiter les bases de données d’un site
                                            internet. A travers des requêtes SQL, il est possible de rechercher un ensemble
                                            de données, de les modifier ou de les supprimer. Malgré sa prédominance d’usage
                                            pour les sites web, SQL n’est pas seulement dédié aux web développeurs. Il est
                                            également utilisé dans plusieurs autres domaines tels que: le data Analyst,
                                            l’intégration de données, l’opération de saisie et la gestion de bases de
                                            données… Aujourd’hui, la maîtrise de SQL est nécessaire pour débuter dans le
                                            monde de la programmation web, que ce soit en tant que développeur full-stack,
                                            data scientist ou encore développeur back-end.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-wordpress.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">Wordpress </h4>
                                        <p class="mb-0 text-base">
                                            WordPress est un système de gestion de contenus (appelé CMS). Il permet de créer
                                            et de gérer le contenu d’un site internet sans avoir de notion en programmation.
                                            Des centaines de thèmes de sites préconfigurés sont disponibles sur Internet
                                            afin de personnaliser au maximum la conception de son site. Que ce soit pour un
                                            site-vitrine, un site e-commerce ou encore un blog, nombre de plugins se
                                            trouvent à disposition sur la plateforme afin d’avoir un rendu à la fois
                                            professionnel, unique et à bas coût. La simplicité d’usage de WordPress et les
                                            rendus obtenus expliquent son succès chez les profanes du code. C’est de loin le
                                            CMS le plus populaire au monde en plus d’être gratuit et open-source. Conçu sous
                                            PHP, WordPress est l’outil qui a démocratisé la création des sites internet, sa
                                            maîtrise est donc un élément essentiel pour tout web développeur et permet aussi
                                            d’accéder à un poste de développeur WordPress.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-html.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">HTML/CSS</h4>
                                        <p class="mb-0 text-base">
                                            HTML (HyperText Markup Langage) est un langage front-end qui permet de rédiger
                                            une page web et d’élaborer sa structure. Il est généralement accompagné de CSS
                                            et JavaScript dans la création de pages. HTML donne du sens au bloc de texte
                                            grâce à des balises et permet de façonner la structure des paragraphes. C’est
                                            évidemment un langage qui évolue au fil du temps et le World Wide Web Consortium
                                            définit ses conventions. Contrairement à HTML, CSS (Cascading Style Sheets) est
                                            un langage qui définit l’apparence d’une page web.
                                            Il vient compléter HTML qui lui permet d’intégrer du texte et de structurer une
                                            page web. Savoir coder en CSS permet de modifier le look d’une ou plusieurs
                                            pages, comme les couleurs de polices ou les formes intégrées à une page.
                                            L’utilisation ne se limite pas à celà et permet de renforcer l’esthétique d’un
                                            site. Il est très apprécié en Web design, en communication et digital marketing.
                                            À l’heure actuelle, la combinaison HTML/CSS est le langage de base du web
                                            développement. Il est donc obligatoire de maîtriser ces langages pour en exercer
                                            d'autres et trouver un emploi en tant que développeur full stack ou en
                                            développeur front-end.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-symfony.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">Symfony & Twig</h4>
                                        <p class="mb-0 text-base">
                                            Symfony est une librairie open-source créée et éditée par SensioLabs en 2005.
                                            Cette puissante librairie est utilisée pour développer des applications ou des
                                            sites web complexes en permettant une mise en commun du projet avec d’autres
                                            utilisateurs. L’essence même de cette technologie est de fédérer la communauté
                                            en lui permettant de contribuer aux évolutions, modifications et corrections que
                                            partagent les autres contributeurs. Grâce à ses librairies, il facilite le
                                            développement d’application et de sites web et permet de définir un cadre de
                                            travail, sur la base du langage de programmation PHP. La finalité première de
                                            son fondateur est de contribuer à l’intérêt commun. Symfony est le leader sur le
                                            marché français et n°2 mondial. Plus de 600 000 développeurs web dans 120 pays
                                            utilisent ce Framework. C’est une librairie indispensable à tout développeur,
                                            qu’il soit junior ou expert.<br />
                                            Utilisé par défaut par Symfony, Twig est un moteur de Template open source qui
                                            facilite la conception de logiciels et d’applications web PHP. Il permet
                                            également d’assurer leur maintenance et leur sécurisation. La particularité de
                                            Twig est qu’il possède une syntaxe beaucoup plus explicite et supporte assez de
                                            fonctionnalités telles que : la sécurisation
                                            automatique et la notion d’héritage. C’est un outil qui est facile d’usage.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-nodejs.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">
                                            Node.JS
                                        </h4>
                                        <p class="mb-0 text-base">
                                            Node.Js est une librairie d’environnement JavaScript qui lui permet d’être
                                            utilisé en back-end dans le développement de sites et d’applications web. C’est
                                            un open-source qui révolutionne le web et évolue de manière conséquente. La
                                            particularité de Node.Js est d’être en effet, un langage asynchrone orienté
                                            événement, donc conçu pour générer des
                                            applications extensibles. De nombreuses applications performantes ont été
                                            développées grâce à cet outil car il permet plus de possibilités
                                            techniques.<br />
                                            La Silicon Valley et les entreprises françaises les plus innovantes ont adopté
                                            Node.Js et sont en quête de développeurs back-end et/ou full-stack maîtrisant ce
                                            framework. Les développeurs back-end maîtrisant Node.Js sont les mieux
                                            rémunérés.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-angular.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">
                                            Angular
                                        </h4>
                                        <p class="mb-0 text-base">
                                            Angular est une librairie open-source (libre de droit) créée en 2014 par la
                                            communauté Google. Ce langage n’est autre que la réécriture de son prédécesseur
                                            Angular.Js sorti en 2009.
                                            Il a pour spécificité de permettre la création d’interfaces clients pour les
                                            développeurs. En effet, Angular est apparu principalement pour répondre aux
                                            standards du web d’aujourd’hui, plus particulièrement aux composants web
                                            introduits par HTML 5. A cela s’ajoute la nouvelle version de JavaScripts (ES6)
                                            qui demande une version d’AngularJs plus performante et revisitée. Ainsi est né
                                            Angular. C’est une librairie utilisée par la plupart des webdesigners et
                                            développeurs qui travaillent sur l’interface client. Aujourd’hui, la
                                            standardisation des composants web rend importante la maîtrise d’Angular pour un
                                            développeur full-stack ou back-end.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/path-react.jpg" alt="" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">
                                            React Native
                                        </h4>
                                        <p class="mb-0 text-base">
                                            React Native est un framework JavaScript qui est open source et qui permet de
                                            développer des applications iOS et Android. Il permet un rendu natif, fluide et
                                            performant. Elle fait la suite à la librairie React qui a été développée deux
                                            ans auparavant par un ingénieur Facebook (Jordan Walke). Facebook, Instagram,
                                            Skype, Discord et bien d’autres ont fait le choix de ce framework pour leurs
                                            applications mobiles.<br />
                                            React Native est une référence dans le développement d’applications mobiles et
                                            cette affirmation est valable partout dans le monde. Ce qu’offre React Native
                                            est unique et sa maîtrise est fortement appréciée dans le monde de la Tech.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex">
                                    <img src="../assets/images/path/flutter.png" alt="flutter-img" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">
                                            Flutter
                                        </h4>
                                        <p class="mb-0 text-base">
                                            Flutter est un Framework de développement d'application mobile open-source
                                            développé par Google. Il permet de créer des applications pou iOS et Android à
                                            partir d'une seule base de code en utilisant le langage Dart. Il offre une
                                            interface uilisateur native, des performances élevées et une productivité accrue
                                            pour les développeurs. Flutter prend en charge les widgets modernes, les
                                            animations riches et transitions fluides, ce qui le rend populaire pour la
                                            création d'expériences utilisateur excepetionnelles. De plus, sa communauté en
                                            constatnte croissance offre de nombreurses bibliothèques et outils pour les
                                            développeurs pour accélérer leur processus de développement.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white mx-auto my-10">
                    <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
                        <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                            <div class="flex justify-between items-center p-4">
                                <div class="flex pb-5">
                                    <img src="../assets/images/path/spring.png" alt="spring-img" class="w-12 h-12" />
                                    <div class="ml-3">
                                        <h4 class="mb-1">
                                            Spring
                                        </h4>
                                        <p class="mb-0 text-base">
                                            Spring est un framework de développement Java open-source qui vise à rendre le
                                            développement d'application plus simple et plus rapide. Il fournit un ensemble
                                            de fonctionnalités pour la gestion des transactions, la sécurité, la gestion des
                                            dépendances, la gestion des données et plus encore. Spring offre une
                                            architecture modulaire qui permet aux développeurs de choisir les composants
                                            qu'ils souhaitent utiliser, ce qui leur donne un grand degré de flexibilité et
                                            de contrôle sur leur projet. Il s'intègre également facilement avec d'atures
                                            technologies Java telles que Hibernate, JPA et Struts. Avec une large communauté
                                            de développeurs et de contributeurs, Spring est considéré comme l'un des
                                            Frameworks Java les plus fiables et les plus populaires pour le développement
                                            d'applications professionnelles.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class=" bg-slate-100  ">
        <div class="py-8 grid grid-cols-1 bg-slate-100 xl:px-28 container mx-auto px-5">
            <div class="mb-10  ">

                <div class="">
                    <h2 class="flex text-5xl xl:text-4xl font-bold text-indigo-950 m-4">Compétences opérationnelles.</h2>
                    <p class="text-xl text-gray-600 m-4">
                        Au delà des langages informatique de codage proprement dits, il y a d'autres compétences
                        essentielles
                        à acquérir et qui font toute la différence entre un développeur traditionnel et un développeur
                        avec beaucoup de valeur ajoutée pour l'organisation qu'il va rejoindre.
                    </p>
                    <p class="text-xl text-gray-600 m-4">
                        Il s'agit notamment de sujets pointus comme la cybersécurité ou plus généralistes comme la gestion
                        des projets en tant en tels et des compétences comportementales (communication, expression,
                        développement personnel et soft skills) spécifiques aux
                        développeurs et que nous vous aiderons à acquérir… Maîtriser ces points, c’est comme ajouter une
                        cerise sur un gâteau, une touche de rouge dans une peinture pour faire frétiller l’œil du
                        spectateur… Bref vous avez compris, c’est au final ce qui va faire la différence !
                    </p>
                </div>
            </div>

            <div class="grid grid-cols-1  gap-4">
                <div class="lg:col-start-2 lg:col-span-10 col-span-12 mb-5">
                    <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                        <div class="flex justify-between items-center">
                            <div class="flex">
                                <span class="text-yellow-500 text-xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                        class="bi bi-shield-check" viewBox="0 0 16 16">
                                        <path
                                            d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                                        <path
                                            d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                </span>
                                <div class="ml-3">
                                    <h4 class="font-bold text-xl mb-1">Cybersécurité et RGPD</h4>
                                    <p class="mb-3 text-base">
                                        La cybersécurité est un ensemble de solutions et de techniques visant à protéger
                                        la disponibilité, l’intégrité et la confidentialité des informations. On l’appelle
                                        aussi sécurité des systèmes d’information ou sécurité informatique. Elle couvre tout
                                        le cycle de vie des données, depuis leur génération passant par leur traitement à
                                        leur transfert, stockage et suppression. La cybersécurité permet aux particuliers
                                        et aux entreprises de prévenir efficacement les attaques ou menaces informatiques.
                                        Sa stratégie repose essentiellement sur le renforcement de trois capacités:
                                        Prévention, Détection et Réaction. Utilisée dans plusieurs domaines de
                                        l’informatique,
                                        la cybersécurité peut être divisée en différentes catégories telles que: la sécurité
                                        réseaux,
                                        la sécurité des applications, la sécurité des informations…
                                    </p>
                                    <p class="mb-0 text-base">
                                        Le Règlement Général sur la Protection de Données « RGPD », est la loi qui
                                        règlemente
                                        le traitement des données personnelles sur le territoire européen. A ce titre, il
                                        est
                                        indispensable à connaitre pour un développeur codeur qui travaille dans un contexte
                                        international.
                                        Il vise à protéger les personnes physiques contre un traitement abusif de leurs
                                        données
                                        à caractère personnel et d’engager la responsabilité des acteurs de ce traitement.
                                        Cette loi s’applique à toutes les entreprises qui font de la collecte et du stockage
                                        des informations personnelles des citoyens de l’Union européenne.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg:col-start-2 lg:col-span-10 col-span-12 mb-5">
                    <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                        <div class="flex justify-between items-center">
                            <div class="flex">
                                <span class="text-yellow-500 text-xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                        class="bi bi-list-check" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                </span>
                                <div class="ml-3">
                                    <h4 class="font-bold text-xl mb-1">Gestion de projet</h4>
                                    <p class="mb-3 text-base">
                                        Avant d’être une méthodologie de travail, la gestion de projet est tout d’abord un
                                        outil
                                        de management. Elle permet d’organiser la bonne conduite d’un projet en vue d’en
                                        atteindre
                                        les objectifs. Plus clairement, la gestion de projet est une méthode globale qui
                                        consiste à
                                        trouver la bonne formule entre les coûts et les ressources disponibles pour
                                        l’atteinte des
                                        objectifs. C’est un outil au service de groupes qui partagent des objectifs communs
                                        et qui
                                        travaillent en équipe. Différentes méthodologies sont utilisées au niveau de la
                                        gestion de projet.
                                        Il s’agit entre autres des plus répandues telles que : la méthode Waterfall, la
                                        méthode cycle en V,
                                        méthode Pert et la méthode agile qui vous seront présentées et explicitées en vous
                                        en donnant les bases.
                                    </p>
                                    <p class="mb-0 text-base">
                                        Et en gestion de projet, au-delà de process bien définis, il faut développer et
                                        maitriser aussi ses compétences relationnelles pour bien travailler ensemble comme
                                        la disponibilité, l'attention, l'empathie, la bienveillance aux autres… Eh ben oui :
                                        -)
                                        un développeur codeur ne fait pas que coder dans son coin, il communique aussi avec
                                        les autres.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg:col-start-2 lg:col-span-10 col-span-12 mb-5">
                    <div class="bg-white shadow-md rounded-lg p-4 border mb-4 hover:shadow-lg">
                        <div class="flex justify-between items-center">
                            <div class="flex">
                                <span class="text-yellow-500 text-xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                        class="bi bi-gear" viewBox="0 0 16 16">
                                        <path
                                            d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                        <path
                                            d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                    </svg>
                                </span>
                                <div class="ml-3">
                                    <h4 class="font-bold text-xl mb-1">Compétences comportementales</h4>
                                    <p class="mb-3 text-base">
                                        Les compétences purement techniques doivent être complétées d'autres compétences
                                        dites comportementales (communication, expression, développement personnel et soft
                                        skills) et qui sont nécessaires à la réalisation des missions des collaborateurs.
                                    </p>
                                    <p class="mb-3 text-base">
                                        Les soft skills par exemple ('compétences comportementales' ou 'savoir-être' en
                                        français) sont plus difficiles à appréhender que les compétences techniques pour
                                        lesquelles un test de connaissance ou épreuve pratique suffisent généralement à
                                        mesurer le degré d'expertise auquel on est parvenu. Les soft skills comportent des
                                        variations entre collaborateurs avec des formes d'expression différentes. Elles sont
                                        d'ailleurs difficilement quantifiables parce qu'elles ont la particularité de devoir
                                        s'adapter aux situations (contextes, missions à accomplir, interactions humaines,
                                        niveau de stress...). De ce fait, l'ouverture d'esprit, la curiosité, l'adaptabilité
                                        par exemple peuvent varier considérablement d'un contexte et d'une personne à
                                        l'autre. Il faut bien comprendre qu'il ne s'agit pas de traits de caractère mais
                                        bien de compétences qui peuvent se travailler et se développer... et ça c'est
                                        réconfortant à savoir :-)
                                    </p>
                                    <p class="mb-0 text-base">
                                        Nous vous aiderons à développer les neuf soft skills indispensables à un développeur
                                        codeur qui veut se démarquer du lot des développeurs…
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Info student  -->
    <infostudent />
    <!-- Footer -->
    <pagefooter />
</template>