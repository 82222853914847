<script setup>
</script>

<template>
  <!-- Infos student -->
  <div class="py-20 bg-[#f4a62a]">
    <div class="container mx-auto">
      <div class="flex justify-center text-center">
        <div>
          <h2 class="text-5xl font-bold text-white mb-5">Recrutez vos futurs alternants !</h2>
          <p class="text-xl font-semibold text-white px-12 mb-10">Vous cherchez des talents ? Nous les formons</p>
          <div class="">
            
            <router-link to="/ContactEntreprises">
              <a class="bg-white text-black border font-semibold py-3 px-6 rounded-lg">Parrainer un étudiant</a>
                        </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
