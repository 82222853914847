<script setup>
// import axios from 'axios';
import { onMounted, ref } from 'vue';

const debutInscription = ref('');
const debutconcours = ref('...');
const debutCours = ref('...');

const getDate = () => {
  // fetch(`http://localhost/highfive_site/backend/route.php?goto=infos`)
      fetch(`/backend/route.php?goto=infos`)
      .then(response => response.json())
      .then(response => {
        // Options pour le format de date en français
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        debutInscription.value = response.depot_dossier ? new Date(response.depot_dossier).toLocaleDateString('fr-FR', options) : null;
        debutCours.value = response.next_renter ? new Date(response.next_renter).toLocaleDateString('fr-FR', options) : null;
        debutconcours.value = response.next_concours ? new Date(response.next_concours).toLocaleDateString('fr-FR', options) : null;

        // console.log(debutInscription.value,
        //   debutCours.value,
        //   debutconcours.value);
      })
      .catch(error => {
        // console.log("je test");
        console.error('Erreur lors de la récupération des données :', error);
      });
};

onMounted(() => {
  getDate();
  // console.log(debutInscription.value);
  // console.log(debutCours.value);
  // console.log(debutconcours.value);
});
</script>

<template>
  <!-- Infos student -->
  <div class="py-20 bg-[#f4a62a]">
    <div class="container mx-auto">
      <div class="flex justify-center text-center">
        <div>
          <h2 class="mb-5 text-5xl font-bold text-white">Prochaine session</h2>
          <h2 v-if="debutInscription" class="my-5 text-3xl font-semibold text-white">Date de début des inscriptions : {{
            debutInscription }}</h2>
          <h2 v-if="debutconcours" class="my-5 text-3xl font-semibold text-white">Date de démarrage du concours : {{
            debutconcours }}</h2>
          <p v-if="debutCours" class="px-12 mb-10 text-2xl font-semibold text-white">Date de début des cours : {{
            debutCours }}</p>
          <div class="">
  
            <router-link to="/Candidature">
              <a class="px-6 py-3 font-semibold text-black bg-white border rounded-lg">Déposer ma
                candidature</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
