<template>
  <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />

  <div class="py-10 bg-white" ref="componentRef">

    <div class="md:mx-auto mx-5">
      <div class="flex justify-center">
        <div class="lg:w-2/3 md:w-full w-full">
          <div class="text-center">
            <h1 class="text-4xl font-bold">Données personnelles</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-10 bg-white">
    <div class=" md:mx-auto mx-5">
      <div class="flex justify-center">
        <div class="lg:w-2/3 md:w-full w-full">
          <div class="mt-5 mb-5 flex">
            <div>
              <h3 class="text-xl font-bold">Protection des données personnelles :</h3>
              <p class="">
                Conformément à la loi n° 2009-0- du 2 mai 2009 portant protection des données à caractère personnel en
                République du Bénin,
                et conformément à la loi du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés de la
                République Française et au Règlement Général sur la Protection des Données européen (RGPD),
                vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui
                vous concernent, que vous pouvez exercer en vous adressant à mailto:contact@highfiveuniversity.com.
              </p>
            </div>
          </div>
          <div class="mt-5 mb-5 flex">
            <div>
              <h3 class="text-xl font-bold">Données personnelles collectées</h3>
              <p class="">
                Les informations personnelles collectées via le site highfiveuniversity.bj sont les suivantes :
              </p>
              <p class="mb-1 ">• Pour <span class="text-dark font-semibold">« formulaire de contact »</span>,
                pour les étudiants : identité, coordonnées, études et formation, motivations et attentes.</p>
              <p class="mb-1 ">• Pour <span class="text-dark font-semibold">« Formulaire de contact »</span>,
                pour les entreprises : Nom de l'entreprise, secteur d'activité, nombre de salariés, adresse
                /localisation, tel accueil, noms et prénoms,
                fonction au sein de l'organisation, téléphone, e-mail, objet de la demande, échéance.</p>
              <p class="mb-1 ">• Pour <span class="text-dark font-semibold">« formulaire de contact »</span>,
                tous publics : noms et prénoms, téléphone, e-mail, nature de la prise de contact.</p>
              <p class=" mt-3">
                Ces données sont utilisées par l'équipe de HIGHFIVE University, notamment son corps enseignant et son
                équipe administrative, à des fins d'inscription des étudiants, pour répondre aux demandes des
                entreprises et à toute demande d'information.
                Elles sont traitées exclusivement par HIGHFIVE University, filiale de Glitter SAS ou toute autre entité
                du Groupe Vipp auquel Glitter SAS appartient.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Info student  -->
  <infostudent />
  <!-- Footer -->
  <pagefooter />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';


const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
  scrollToTop();
});

const scrollToTop = () => {
  if (componentRef.value) {
    componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

  }
}

</script>