<template>
  <div class="py-24 bg-slate-100">
        <div class="container mx-auto px-5 ">
            <div class="w-full mx-auto md:w-4/5 ">
                <div class="mx-2">
                    <span class="text-amber-500 font-semibold text-lg uppercase">Localisation</span>
                    <h2 class="text-4xl xl:text-3xl font-bold text-indigo-950 my-4">Nous trouver.</h2>
                </div>
            </div>
            <div class="flex flex-col gap-4 lg:px-20 xl:flex-row xl:justify-center">
                <div class="basis-full xl:basis-2/5">
                    <div class="border mb-4 rounded-md shadow-md bg-white">
                        <div class="p-5">
                            <div class="mb-3">
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold text-lg md:text-xl">Au cœur du centre urbain,</h3>
                            <p class="mb-0 text-gray-600">
                                pour faciliter l’accès de nos équipes et le recrutement d’une population diplômée,
                                s’exprimant parfaitement en français.
                            </p>
                        </div>
                    </div>
                    <div class="border mb-4 rounded-md shadow-md bg-white">
                        <div class="card-body p-5">
                            <div class="mb-3">
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold text-lg md:text-xl">À proximité de l’aéroport international,</h3>
                            <p class="mb-0 text-gray-600">
                                pour limiter les temps de déplacement de nos clients et de nos collaborateurs
                                <span class="fw-bold">(10mn en voiture)</span>.
                            </p>
                        </div>
                    </div>
                    <div class="border mb-4 rounded-md shadow-md bg-white">
                        <div class="card-body p-5">
                            <div class="mb-3">
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold text-lg md:text-xl">Proche des grandes administrations</h3>
                            <p class="mb-0 text-gray-600">
                                pour bénéficier des meilleures infrastructures techniques.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="basis-full xl:basis-2/5">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15861.300768474137!2d2.4094342322227518!3d6.3519288264546345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10235509a86a5ab5%3A0x284ff4b0eac477a7!2sVIPP%20Interstis%20Ganhi!5e0!3m2!1sfr!2sfr!4v1658314273088!5m2!1sfr!2sfr" width="100%" height="100%"  style="border:0; border-radius: 5px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div class=" mb-8 mt-14">
                <div class=" mx-auto lg:mx-10">
                    <p class="text-xl text-gray-600 font-bold mb-5 xl:text-3xl">
                        HIGHFIVE University est localisée dans les locaux de VippInterstis : 10 000 m2 sur 6 niveaux.
                    </p>
                    <ul class="list-unstyled flex flex-col gap-2 text-lg md:text-xl text-gray-500">
                        <li class="flex gap-1"><span class="text-amber-500"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        </span> 13 plateaux d’environ 600 m²</li>
                        <li class="flex gap-1"><span class="text-amber-500"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg></span> 9 salles de formation (dont 3 dédiés)</li>
                        <li class="flex gap-1"><span class="text-amber-500"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg></span> Salle de repos, bibliothèques, salles de sport, infirmerie, crèche d'entreprise</li>
                        <li class="flex gap-1"><span class="text-amber-500"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg></span> Bureau d’accueil visiteurs</li>
                        <li class="flex gap-1"><span class="text-amber-500"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg></span> Gardiennage et casiers</li>
                    </ul>
                    
                    <p class="mt-3 text-lg md:text-xl text-gray-500">Les étudiants pourront bénéficier de certaines de ces installations.</p>
                </div>
            </div>
        </div>
    </div>
</template>