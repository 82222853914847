<script setup>
    import ParrainageComponent from '@/components/Contents/ParrainageComponent.vue';
    import Pagefooter from '@/components/Contents/FooterComponent.vue';
    import { ref, onMounted } from 'vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';

    const society=ref('');
    const activity=ref('');
    const number_employees=ref('');
    const address=ref('');
    const society_phone=ref('');
    const first_name=ref('');
    const last_name=ref('');
    const functions=ref('');
    const personnal_phone=ref('');
    const email=ref('');
    const message=ref(false);
    const Entreprisemessage=ref('');

    async function sendData() {
    let data =
    {
        society:society.value,
        activity:activity.value,
        number_employees:number_employees.value,
        address:address.value,
        society_phone:society_phone.value,
        first_name:first_name.value,
        last_name:last_name.value,
        functions:functions.value,
        personnal_phone:personnal_phone.value,
        email:email.value,
        message:Entreprisemessage.value,

    }

//    fetch(`http://localhost/highfive_site/backend/route.php?goto=entreprise&target=entreprise_save`,
   fetch(`/backend/route.php?goto=entreprise&target=entreprise_save`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
    ).then(response =>response.json()).then(response=>{
        message.value=response;
    })
   

}
    
const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
    scrollToTop();
});

const scrollToTop =() => {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

    }
}

</script>
<template>

    <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center"/>
    <div class="py-6 py-lg-12 bg-white" ref="componentRef">
        
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="" v-if="message">
                <div class="w-full">
                    <div class="text-white bg-green-400 py-8 mx-auto text-5xl text-center" v-if="message.code==200">
                        Votre demande a bien été transmise. Merci!
                    </div>
                </div>
            </div>            

            <div class="flex gap-5 flex-col xl:flex-row px-5 mx-auto" v-if="!message">
                <form @submit.prevent="sendData">
                <!-- Title -->
                <div class="w-full">
                    <div class="mb-4 ">
                        <span class="uppercase font-bold text-amber-500">Contactez-nous</span>
                        <h1 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Formulaire de contact</h1>
                        <p class="mb-0 text-xl text-gray-600 my-4">Vous travaillez en entreprise et vous souhaitez en savoir plus sur nos contrats d'alternance, sur les modalités d'accueil d'un étudiant, sur le type de mission que vous pouvez lui confier sous notre encadrement pédagogique, les modalités financières ou toute autre question...</p>
                        <p class="mb-0 text-xl text-gray-600 my-4">Alors n'hésitez pas, contactez notre équipe et nous nous ferons un plaisir de répondre à toutes vos questions.</p>
                    </div>
                </div>


                <div class="w-full">

                    <div class="mb-4  ">                        
                        <div class="">
                            <div class="mb-3">
                                <div class="mb-3">
                                    <label for="societe">Société<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Votre société..." class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="society">
                                </div>
                            </div>
                            <div  class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div class="mb-3">
                                    <label for="activite">Activité<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Votre activité..." class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="activity">
                                </div>
                                <div class="mb-3">
                                    <label for="nbre_employe">Nombre d'employés<span class="text-red-500">*</span></label>
                                    <input type="number" placeholder="Ex: 12" class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="number_employees">
                                </div>
                            </div>
                            <div class="mb-3 2">
                                <div class="mb-3">
                                    <label for="adress">Adresse<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Adresse complète de l'entreprise..." class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="address">
                                </div>
                            </div>
                            <div class="mb-3 2">
                                    <label for="phone">Téléphone<span class="text-red-500">*</span></label>
                                    <input type="tel" placeholder="Ex: 62871921" class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="society_phone">
                            </div>
                        </div>
                    </div>
                    <hr class="w-full my-10">

                    <div class="mb-4  ">                        
                        <div class="">
                            <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div class="mb-3">
                                    <label for="fname">Prénom<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="John" class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="first_name">
                                </div>
                                <div class="mb-3">
                                    <label for="lname">Nom<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Doe" class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="last_name">
                                </div>
                            </div>
                            <div  class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div class="mb-3">
                                    <label for="fonction">Fonction<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Fonction dans l'entreprise" class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="functions">
                                </div>
                                <div class="mb-3">
                                    <label for="phone">Téléphone personnel<span class="text-red-500">*</span></label>
                                    <input type="tel" placeholder="Ex: 62871921" class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="personnal_phone">
                                </div>
                            </div>
                            <div class="mb-3 2">
                                <div class="mb-3">
                                    <label for="email">Email<span class="text-red-500">*</span></label>
                                    <input type="email" placeholder="john.doe@gmail.com" class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required v-model="email">
                                </div>
                            </div>
                            <div class="mb-3 2">
                                <label for="message">Message<span class="text-red-500">*</span></label>
                                <textarea class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" name="Message" id="" cols="20" rows="5" placeholder="Votre message..." required v-model="Entreprisemessage"></textarea>
                            </div>
                            <div class="col-span-2 mt-3">
                                <button class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">Envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </div>
        </div>
    </div>
    <!-- parrainage footer -->
    <ParrainageComponent />
    <!-- Footer -->
    <pagefooter />
</template>









