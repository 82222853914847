<script setup>
import { ref } from 'vue';
import { onMounted } from 'vue';

const positionScroll = ref(window.scrollY);

const updateScrollPosition = () => {
    positionScroll.value = window.scrollY;
};

onMounted(() => {
    window.addEventListener('scroll', updateScrollPosition);
});

function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>
<template>
    <div v-if="positionScroll >= 600" @click="scrollToTop()">
        <button class="text-amber-500 flex" @click="closeMenu">
            <span>
                <svg viewBox="0 0 24 24" fill="white" stroke="white" class="w-6 h-6">
                    <path fill-rule="evenodd"
                        d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                        clip-rule="evenodd" />
                </svg>

            </span>
        </button>
    </div>
</template>
