<script setup>


</script>
<template>
    <div class=" bg-slate-100 mx-auto my-10">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="py-10">
                <span class="text-amber-500 text-lg uppercase ">L'élite de la programmation</span>
                <h2 class="text-4xl xl:text-3xl font-bold text-indigo-950 my-4">HIGHFIVE University, origine.</h2>
                <p class="md:text-xl text-gray-600 my-4">
                    HIGHFIVE University est née de la volonté du <a href="https://www.vippinterstis.com/"
                        target="_blank" class="text-amber-500">Groupe Vipp</a> d’ouvrir localement des
                    écoles de codage dans les pays où le Groupe est présent à travers ses activités de gestion
                    de l’expérience client. La connaissance de ces territoires et du dynamisme de sa jeunesse d’une part, et des attentes des entreprises mondiales en matière de codage d’autre part désignent
                    Vipp comme l’un des acteurs les plus légitimes pour proposer une filière de formation adaptée et
                    efficace.
                </p>
                <p class="md:text-xl text-gray-600">
                    L’objectif est de former des techniciens aux métiers du digital, selon des standards
                    internationaux. Ce sont un cursus et une formation d’excellence qui sont proposés, tant au niveaux
                    des apports pédagogiques, que des techniques d’apprentissage et du cadre scolaire et parascolaire.
                </p>
                <p class="md:text-xl text-gray-600 my-4">
                    Une première université est ouverte à Cotonou (Bénin) et d’autres sont à l’étude
                    dans les autres pays où le groupe est présent : Cameroun, Togo, Rwanda.
                </p>
                <div class="grid grid-col-12 grid-row-2 gap-2 my-12">
                    <!-- gallery-item -->
                    <figure class="col-span-4 row-start-1">
                        <img src="../../assets/images/image14.webp" alt="Gallery image 1" class="rounded object-cover h-full">>
                    </figure>
                    <!-- gallery-item -->
                    <figure class="col-span-2 row-start-1 ">
                        <img src="../../assets/images/image6.webp" alt="Gallery image 2" class="rounded object-cover h-full">
                    </figure>
                    <!-- gallery-item -->
                    <figure class="col-span-3 row-start-1">
                        <img src="../../assets/images/image9.webp" alt="Gallery image 3"
                            class=" h-full object-cover
                         rounded">
                    </figure>
                    <!-- gallery-item -->
                    <figure class="row-start-2 col-start-1 col-end-3">
                        <img src="../../assets/images/image3.webp" alt="Gallery image 4"
                            class="rounded object-cover h-full">
                    </figure>
                    <!-- gallery-item -->
                    <figure class="row-start-2 col-start-3 col-end-8">
                        <img src="../../assets/images/image11.webp" alt="Gallery image 5"
                            class="rounded">
                    </figure>
                    <!-- gallery-item -->
                    <figure class="row-start-2 col-start-8 col-end-10 ">
                        <img src="../../assets/images/image1.webp" alt="Gallery image 6"
                            class="rounded h-full object-cover w-full ">
                    </figure>
                </div>
            </div>
    </div>

</div>
</template>
<style scoped>

</style>