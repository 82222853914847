<script setup>
import { ref, onMounted } from 'vue';
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';


const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
  scrollToTop();
});

const scrollToTop = () => {
  if (componentRef.value) {
    componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

  }
}



const student_faq = ref([
  {
    title: "C’est quoi HIGHFIVE University?",
    content: "HIGHFIVE University est une école de codage créée par le Groupe VIPP avec un concept original en rupture.",
    isCollapsed: false,
    link: null,
    link_content: null

  },
  {
    title: "Je n’ai jamais touché à une ligne de code, est-ce un problème ?",
    content: "Pas du tout. A part cette volonté d'apprendre, votre disponibilité et votre implication, vous n'avez besoin de rien pour suivre notre formation. En nous rejoignant, vous bénéficierez d’une formation complète. Ayez confiance ensemble nous y arriverons.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: " Faut-il être présent à temps-plein dans cette formation ?",
    content: "Oui. C’est une absolue nécessité. Pour suivre nos cours, l'assiduité de nos étudiants est de rigueur. A HIGHFIVE University, la formation est à 100% en présentiel, y compris pendant la période en alternance. C'est une formation exigeante et avoir une activité en dehors des cours est impossible. Un contrôle est d'ailleurs organisé chaque semaine afin de s'assurer du niveau de compréhension de nos apprenants.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Où est située l'école ?",
    content: 'L\'école est située dans l\'immeuble Vipp Interstis à Ganhi, Cotonou en face de SONIBANK (<a href="https://goo.gl/maps/GXLxWJZgvSXr9cb17" target="_blank" class=\'text-link-color\'>lien GPS</a>).',
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: " Quels profils sont acceptés ?",
    content: "Pour être étudiant à HIGHFIVE University, deux critères sont à remplir : il faut avoir un niveau d'étude Bac+2 minimum et participer à notre concours.Alors intéressé ?",
    isCollapsed: false,
    link: '/JoinUs',
    link_content: "<a  class='text-link-color'> Cliquer ici</a>."
  },
  {
    title: "À combien s'élèvent les frais de scolarité ?",
    content: "Bonne question ou alors c'est pour relire cette bonne nouvelle : il n'y a pas de frais de scolarité... <strong>PAS DE FRAIS DE SCOLARITE</strong> pour les étudiants... C'est une des caractéristiques tout à fait unique de HIGHFIVE University... Mais bien sûr il faut nous rejoindre et suivre assidûment notre cursus universitaire et chaque semaine, il y a des contrôles pour s'assurer que c'est bien le cas. C'est le deal qu’on vous propose... « Give me five ? »",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "HIGHFIVE University m'aide-t-elle à trouver une entreprise à la fin de mon cursus ?",
    content: "Bien sûr ! Grâce à notre programme d'alternance, nos étudiants ont l'avantage de découvrir le monde professionnel qui leur devient familier. Cela favorise l'obtention du poste recherché. Avec votre diplôme High Five en poche, votre emploi est assuré.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Quelle est la journée type d'un étudiant en formation et en alternance ?",
    content: "Pendant la période d’enseignement en bootcamp, les cours ont lieu du lundi au vendredi à raison de 8h par jour. Pendant la période d’alternance, votre présence est requise 7h par jour les lundis, mardis, jeudis et vendredis et 8h les mercredis, sans oublier 4 h les samedis. À noter que nous mettons les salles de cours à votre disposition jusqu'à 20h pour vos séances de révision, en prévision de vos contrôles de connaissance.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Puis-je bénéficier d'une bourse ?",
    content: "Non. Rappelez-vous que pour intégrer notre école vous avez juste à payer des frais d’inscription. Et comme il n'y a pas de frais de scolarité pour les étudiants, il n'y a pas de bourse. Bonne nouvelle, car vous ne seriez d'ailleurs pas certain d'obtenir cette bourse. ☺",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Est-il possible d’effectuer son stage à l’étranger ?",
    content: "Non. Les structures partenaires avec lesquelles nous sommes en collaboration se trouvent toutes sur le territoire béninois. De plus, vu qu'il s'agit d’un programme d'alternance, il est souhaitable que votre maître de stage puisse vous rendre visite dans nos locaux.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Comment constater les progrès que l’on fait ? Y-a-t-il des évaluations ?",
    content: "Oui forcément. Durant toute la période de votre cursus, vous êtes dans l'obligation de participer à des contrôles réguliers (près d’un par semaine) qui attesteront du niveau de compréhension et d'assimilation. Ces contrôles détermineront votre capacité à continuer ou non la formation. Evidemment, des sessions de rattrapage sont possibles mais il ne faut pas les cumuler car sinon, vous serez en difficulté…",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Quelle est l'effectif par classe ?",
    content: "A HIGHFIVE University, nous sommes convaincus que moins il y aura d'étudiants par salle de formation, plus le taux de réussite sera élevé. Ainsi nous avons un maximum de 15 étudiants par salle.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "C'est quand la prochaine rentrée ?",
    content: "Notre première promotion d'étudiants de HIGHFIVE University ? Dès l'ouverture de nos portes, en août 2022… Le 29 août précisément. Hâte d'y être. Et si c’est trop court pour vous… Revenez sur notre site car nous prévoyons la rentrée suivante tout début 2023.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Concernant le « matos » et la connexion, j’ai besoin de quoi ?",
    content: "De votre cerveau surtout ☺. Et comme dans tout centre de formation, vous aurez besoin de quoi prendre quelques notes le premier jour. A HIGHFIVE University, les cours sont dispensés à 90% par la pratique. Nos étudiants seront donc beaucoup plus sur écran que dans leurs cahiers. Et ces postes de travail, nous les mettons à votre disposition.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Est-ce que je serai assez compétent pour trouver un emploi après votre formation ?",
    content: "Notre force à HIGHFIVE University, est de garantir à chaque étudiant qui finit son cursus avec succès, une offre d'emploi dans le domaine du développement Web. Si nous pensions que vous ne serez pas performant à l’issue de votre formation, nous ne vous ferions pas une offre pareille.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
]);

// const toggleCollapse_s = (index) => {
//   student_faq.value[index].isCollapsed = !student_faq.value[index].isCollapsed;
// };



const toggleCollapse_s = (index) => {
  const faq = student_faq.value; // Assurez-vous d'avoir accès à votre tableau FAQ

  for (let i = 0; i < faq.length; i++) {
    if (i === index) {
      // C'est l'élément que vous souhaitez déplier
      faq[i].isCollapsed = !faq[i].isCollapsed; // Inversez l'état d'effondrement
    } else {
      // Les autres éléments doivent être repliés
      faq[i].isCollapsed = false;
    }
  }
};





// Entreprises FAQ

const business_faq = ref([
  {
    title: "Je suis une entreprise, comment puis-je postuler pour recevoir des stagiaires ?",
    content: 'C\'est simple, il suffit de rentrer en contact avec nous par mail (entreprises@highfiveuniversity.com) ou par téléphone (+33 1 87 64 43 01) ou tout simplement de ',
    isCollapsed: false,
    link: "/Contact",
    link_content: "<a  class='text-link-color'>cliquer ici</a>."
  },
  {
    title: "En tant qu'entreprise, cela me coûte combien de parrainer un étudiant en alternance?",
    content: 'Si vous vous posez la question, c\'est que vous êtes intéressé, alors contactez-nous en ',
    isCollapsed: false,
    link: "/Contact",
    link_content: "<a  class='text-link-color'>cliquant ici</a>."
  },
  {
    title: "Qu’est-ce qu’un contrat en alternance peut apporter à mon entreprise ?",
    content: "Celà vous permet d’allouer les ressources d’un étudiant à un projet en lien avec notre enseignement de codage. Il travaille donc directement pour vous, sous encadrement de notre corps enseignant. Et à l’issue de son alternance, vous avez même la possibilité de l’intégrer dans vos effectifs, au sein de votre département informatique.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "L’entreprise peut faire travailler l’étudiant sur le projet qu’elle choisit, et même sur plusieurs projets ?",
    content: "Bien sûr, et en lien avec nous. Tant que le projet en question permet à l’étudiant d’approfondir ses connaissances, et permet une continuité de son apprentissage.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "Comment puis-je postuler pour accueillir un ou plusieurs stagiaires ?",
    content: 'C’est simple. Vous avez eu connaissance de notre pédagogie et de nos méthodes d’apprentissage et vous êtes intéressé. Alors postulez en remplissant',
    isCollapsed: false,
    link: "/Contact",
    link_content: "<a class='text-link-color'> ce formulaire</a> pour plus de détails."
  },

  {
    title: "À l’issue du contrat en alternance, mon entreprise peut elle recruter le stagiaire ?",
    content: "Bien sûr. Tout cela dépendra des modalités convenues lors du contrat signé avec HIGHFIVE University. Si votre structure a besoin de codeurs à long terme, il suffira de nous l’indiquer et nous vous accompagnerons dans votre projet.",
    isCollapsed: false,
    link: null,
    link_content: null
  },
  {
    title: "En tant qu'entreprise, cela me coûte combien de recruter des stagiaires chez vous ?",
    content: 'Si vous vous posez la question, c\'est que vous êtes intéressé, alors contactez-nous en ',
    isCollapsed: false,
    link: "/Contact",
    link_content: "<a  class='text-link-color'>cliquant ici</a>"
  },
]);

// const toggleCollapse_b = (index) => {
//   business_faq.value[index].isCollapsed = !business_faq.value[index].isCollapsed;
// };

const toggleCollapse_b = (index) => {
  const faq = business_faq.value; // Assurez-vous d'avoir accès à votre tableau FAQ

  for (let i = 0; i < faq.length; i++) {
    if (i === index) {
      // C'est l'élément que vous souhaitez déplier
      faq[i].isCollapsed = !faq[i].isCollapsed; // Inversez l'état d'effondrement
    } else {
      // Les autres éléments doivent être repliés
      faq[i].isCollapsed = false;
    }
  }
};


const prev_def = (event) => {
  event.preventDefault(); // Empêche le comportement par défaut du lien
};

</script>

<template>
  <!-- ... FAQ Etudiants ... -->
  <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />
  
  <div class="py-10 bg-white" ref="componentRef">

    <div class="w-9/12 mx-auto sm:w-3/5 md:w-8/12 xl:w-2/5">
      <div class="text-center">
        <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-11 h-11 mr-3">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>FAQ Etudiants</h2>
      </div>
      <div class="py-12 flex justify-content-center align-items-center">
        <div class="container mx-auto">
          <div class="accordion accordion-flush" id="accordionExample">
            <div v-for="(item, index) in student_faq" :key="index" class="border p-3 rounded-3 mb-2">
              <h3 class="mb-0 fs-4">
                <a href="#" class="flex items-center text-inherit text-decoration-none" @click="prev_def($event)">
                  <span @click.prevent="toggleCollapse_s(index)" class="me-auto font-semibold hover:text-yellow-500"
                    :class="{ 'text-yellow-500': item.isCollapsed }">{{
                      item.title }}</span>
                  <span class="ms-4 text-gray-600" :class="{ 'rotate-180': item.isCollapsed }"
                    @click.prevent="toggleCollapse_s(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </span>
                </a>
              </h3>
              <div v-if="item.isCollapsed" class="pt-3"><span v-html="item.content"></span><router-link :to="item.link" v-if="item.link != null" v-html="item.link_content" >
              </router-link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- ... FAQ Business ... -->
  <div class="py-10 bg-white">
    <div class="w-9/12 mx-auto sm:w-3/5 md:w-8/12 xl:w-2/5">
      <div class="text-center">
        <h2 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4"><svg xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-11 h-11 mr-3">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
          </svg>FAQ Entreprises</h2>
      </div>
      <div class="py-12 flex justify-content-center align-items-center">
        <div class="container mx-auto">
          <div class="accordion accordion-flush" id="accordionExample">
            <div v-for="(item, index) in business_faq" :key="index" class="border p-3 rounded-3 mb-2">
              <h3 class="mb-0 fs-4">
                <a href="#" class="flex items-center text-inherit text-decoration-none" @click="prev_def($event)">
                  <span @click.prevent="toggleCollapse_b(index)" class="me-auto font-semibold hover:text-yellow-500">{{
                    item.title }}</span>
                  <span class="ms-4 text-gray-600" :class="{ 'rotate-180': item.isCollapsed }"
                    @click.prevent="toggleCollapse_b(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                  </span>
                </a>
              </h3>
              <div v-if="item.isCollapsed" class="pt-3"><span v-html="item.content"></span><router-link :to="item.link" v-if="item.link != null" v-html="item.link_content" >
              </router-link></div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Info student  -->
  <infostudent />
  <!-- Footer -->
  <pagefooter />
</template>

<style>
.text-link-color {
  color: rgb(245 158 11);
  /* Remplacez cette couleur par la couleur que vous souhaitez utiliser */
}
</style>
