<template>
    
    <div class="pt-10 pb-6 bg-white">
        <div class="container mx-auto px-5 lg:px-10">
            <div class="grid grid-cols-1 lg:grid-cols-4 gap-4">
                <div class="col-span-1 lg:col-span-1">
                    <div class="mb-4">
                        <img src="../../assets/images/logo/logo-2.png" height="130" alt="" class="w-32">
                        <div class="mt-1 italic text-gray-500">
                            « Le code informatique est la langue du XXIe siècle et nos jeunes doivent la maîtriser ».
                        </div>
                    </div>
                </div>

                <div class="col-span-1 lg:col-span-1">
                    <div class="mb-4">
                        <h3 class="font-bold text-xl  mb-3">Menu</h3>
                        <ul class="list-none">
                            <li><router-link to="/"><a class="hover:text-amber-400 cursor-pointer text-gray-500 ">Qui sommes nous ?</a>
                                </router-link> </li>
                            <li><router-link to="/Formation"><a class="hover:text-amber-400 cursor-pointer text-gray-500">Formation</a>
                                </router-link></li>
                            <li><router-link to="/Engagements"><a class="hover:text-amber-400 cursor-pointer text-gray-500">Nos engagements</a>
                                </router-link></li>
                            <li><router-link to="/JoinUs"><a class="hover:text-amber-400 cursor-pointer text-gray-500">Nous rejoindre</a>
                                </router-link></li>
                            <li><router-link to="/Entreprise"><a class="hover:text-amber-400 cursor-pointer text-gray-500">Entreprises</a>
                                </router-link></li>
                           
                        </ul>
                    </div>
                </div>

                <div class="col-span-1 lg:col-span-1">
                    <div class="mb-4">
                        <h3 class="font-bold text-xl   mb-3">Support</h3>
                        <ul class="list-none">
                           
                            <li><router-link to="/Faq"><a class="hover:text-amber-400 cursor-pointer text-gray-500">FAQs</a>
                                </router-link></li>
                            <li><router-link to="/Contact"><a class="hover:text-amber-400 cursor-pointer text-gray-500">Contact</a>
                                </router-link></li>
                            <li><router-link to="/ContactEntreprises"><a class="hover:text-amber-400 cursor-pointer text-gray-500">Contact entreprise</a>
                                </router-link></li>
                           
                        </ul>
                    </div>
                </div>

                <div class="col-span-1 lg:col-span-1">
                    <div class="mb-2">
                        <h3 class="font-bold text-xl mb-3">Nous trouvez</h3>
                        <ul class="list-none">
                            <li>
                                <a href="https://goo.gl/maps/GXLxWJZgvSXr9cb17" target="_blank"
                                    class="hover:text-amber-400 text-gray-500">
                                    HIGHFIVE Univeristy, Marché GANHI-01, BP 118 Cotonou.
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>

            <div class="border-t border-gray-300 pt-2 mt-6 flex flex-col lg:flex-row items-center justify-between">
                <div class="mb-4 text-gray-500 lg:mb-0">
                    <span>© 2022 Glitter SAS. Tous droits réservés.</span>
                </div>
                <div class="flex text-gray-500 justify-end">
                    <nav class="space-x-2">
                        <router-link to="/DonneePersonnelle"><a  class="hover:text-amber-400 cursor-pointer ">Données personnelles</a>
                                </router-link>
                        <router-link to="/MentionLegale"><a  class="hover:text-amber-400 cursor-pointer ">Mentions légales</a>
                                </router-link>
                      
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>