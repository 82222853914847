<script setup>
import origine from '@/components/Contents/OrigineComponent.vue';
import concept from '@/components/Contents/ConceptComponent.vue';
import location from '@/components/Contents/LocalisatioComponent.vue';
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';
import { ref, onMounted, onBeforeMount } from 'vue';
import WebinaireCarousel from '@/components/utils/WebinaireCarousel.vue';

const componentRef = ref(null);
const webinaires = ref([]);

async function getAllWebinaire() {
    // fetch(
    //     `http://localhost/highfive_site/backend/route.php?goto=getAllwebinaires&target=getAllWebinaire`,
       
    fetch(
        `/backend/route.php?goto=getAllwebinaires&target=getAllWebinaire`,
        {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        }
    )
        .then((response) => response.json())
        .then((response) => {
            webinaires.value = response;
        });
}
// console.log(webinaires.value);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
    scrollToTop();

});

onBeforeMount(() => {
    getAllWebinaire();
})

function scrollToTop() {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });
    }
}


</script>

<template>
    <div class="w-full mx-auto" ref="componentRef">
        <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />


        <WebinaireCarousel class="hidden md:flex" :webinaire=webinaires />



        <div class="image-container h-screen md:hidden relative">
            <img class="w-full h-screen object-cover" :src="images[currentImage].url" alt="Mobile Slide" />

            <h2 class="monospace absolute  text-xl z-50 top-24 font-bold mb-4 text-white text-center p-4">
                <span class="chevron">&lt;</span>
                {{ images[currentImage].title }}
                <span class="chevron">/&gt;</span>
            </h2>


           <div class="absolute z-50 bottom-44 flex justify-center items-center w-full">
            <router-link  to="/Reunions">
                <a class="bg-gray-100 px-5 py-3 text-sm rounded mt-5 font-bold cursor-pointer">S'inscrire à une séance d'informations</a>
            </router-link>
           </div>

            <!-- Bouton pour faire défiler vers le bas -->
            <span @click="scrollToBottom"
                class="animated-button absolute bottom-28 mt-20 transform -translate-x-1/2 w-full  text-white rounded-full flex justify-center items-center ">
                <svg  @click="scrollToBottom" class="w-8 h-8" data-slot="icon" fill="none" stroke-width="1.5" stroke="#f4a62a"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"></path>
                </svg>
            </span>
        </div>

        <!-- <div class=" md:hidden">
           
            <div class="bg-[#f4a62a] flex">
                <div class=" mx-auto px-5  lg:px-20 border-2">
                
                    <div class="flex flex-col gap-8 lg:flex-row items-center justify-between">
                        <div class="col-xl-5 col-lg-6 col-md-12 ">
                            <div class="py-5 py-lg-0">
                                <h1 class="text-white text-5xl font-bold ">
                                    DEVENEZ DEVELOPPEUR !
                                </h1>
                                <p class="text-white-50 my-4 text-xl text-gray-300 mb-10">
                                    Formez-vous, et construisons ensemble votre avenir professionnel.
                                </p>
                                <router-link class="" to="/Reunions">
        <a class="bg-gray-100 px-5 py-3 text-sm rounded mt-5 font-bold">S'inscrire à nos réunions d'informations</a>
      </router-link>
                            </div>
                        </div>

                        <div class="col-xl-7 col-lg-6 col-md-12 text-lg-end  ">
                            <img src="../assets/images/hero/hero-img.png" alt="image baniere" class="xl:w-5/6">
                        </div>



                        
                    </div>
                </div>
            </div> -->

    </div>

    <!-- Count -->
    <div class="bg-white py-4 shadow-sm">
        <div class="container mx-auto">
            <div class="grid grid-cols-2 lg:grid-cols-4 gap-4">
                <div class="pt-3 mb-3 text-center">
                    <h1 class="text-4xl font-bold mb-0">2 ans</h1>
                    <p class="uppercase text-gray-500 md:text-xl">de formation</p>
                </div>
                <div class="pt-3 mb-3 text-center">
                    <h1 class="text-4xl font-bold mb-0">1940</h1>
                    <p class="uppercase text-gray-500 md:text-xl">heures de cours</p>
                </div>
                <div class="pt-3 mb-3 text-center">
                    <h1 class="text-4xl font-bold mb-0">1630</h1>
                    <p class="uppercase text-gray-500 md:text-xl">heures de projet pour l'entreprise</p>
                </div>
                <div class="pt-3 mb-3 text-center">
                    <h1 class="text-4xl font-bold mb-0">Garantie</h1>
                    <p class="uppercase text-gray-500 md:text-xl">d'employabilité</p>
                </div>
            </div>
        </div>
    </div>



    <!-- Origine -->
    <origine />
    <!-- Concept -->
    <concept />
    <!-- Localisation -->
    <location />
    <!-- Info student  -->
    <infostudent />
    <!-- Footer -->
    <pagefooter />
</template>
<script>
export default {
    data() {
        return {
            currentImage: 0,
            images: [
                {
                    title: 'Rejoignez la meilleure équipe de codeurs du Bénin pour une formation gratuite avec la garantie d\'un emploi.',
                    url: require('../assets/images/image7.webp')
                    // url: require('../assets/images/slide.webp')
                },
                // ... (ajoutez les autres images selon votre besoin)
            ]
        }
    },
    methods: {
        scrollToBottom() {
            window.scrollTo({
                top: window.scrollY + window.innerHeight,
                behavior: 'smooth'
            });
        }
    },
    mounted() {
        setInterval(() => {
            this.currentImage++;
            if (this.currentImage === this.images.length) {
                this.currentImage = 0;
            }
        }, 6000);
    }
}
</script>
  
<style scoped>
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-10px);
    }
}

.mobile-animated-button {
    animation: bounce 3s infinite;
}

.chevron {
    color: red;
    /* Couleur du texte, ajustez selon vos besoins */
    margin: 0 5px;
    /* Marge entre les chevrons et le texte, ajustez selon vos besoins */
}

.monospace {
    font-family: 'Courier New', Courier, monospace;
}
</style>
