<template>
  <!-- <transition name="fade" mode="out-in"> -->
    
    <div :key="currentImage" class="relative image-container">

      <h2 class="text-4xl text-overlay font-semibold mb-4 text-white monospace z-50 absolute top-1/3 left-40 w-[500px] lg:w-[700px]">
        <span class="chevron">&lt;</span>
        {{images[currentImage].title}}
        <span class="chevron">/&gt;</span>
      </h2>

      <router-link class="absolute z-50 bottom-1/3 left-40" to="/Reunions">
        <a class="bg-gray-100 px-5 py-3 text-sm rounded mt-5 font-bold cursor-pointer">S'inscrire à une séance d'informations</a>
      </router-link>

      <img class="w-full h-screen object-cover" :src="images[currentImage].url" />

      <!-- Bouton pour faire défiler vers le bas -->
      <span @click="scrollToBottom"
                class="animated-button absolute bottom-20  transform -translate-x-1/2 w-full  text-white rounded-full flex justify-center items-center ">
                <svg  @click="scrollToBottom" class="w-8 h-8 cursor-pointer" data-slot="icon" fill="none" stroke-width="1.5" stroke="#f4a62a"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"></path>
                </svg>
            </span>

    </div>

  <!-- </transition> -->
</template>

<script>
export default {
  data() {
    return {
      currentImage: 0,
      images: [
        {
          title: 'Rejoignez la meilleure équipe de codeurs du Bénin pour une formation gratuite avec la garantie d\'un emploi.',
          url: require('../../assets/images/image7.webp')
        },
        {
          title: 'Bénéficiez d\'une formation gratuite avec notre équipe d\'experts, et de la garantie d\'un emploi.',
          url: require('../../assets/images/image1.webp')
        },
        {
          title: 'Sans expérience préalable ? Une formation gratuite vous attend, accompagnée de la certitude d\'un emploi.',
          url: require('../../assets/images/image10.webp')
        }
      ]
    }
  },
  methods: {
    scrollToBottom() {
      window.scrollTo({
        top: window.scrollY + 800,
        behavior: 'smooth'
      });
    }
  },
  mounted() {
    setInterval(() => {
      this.currentImage++;
      if (this.currentImage === this.images.length) {
        this.currentImage = 0;
      }
    }, 6000); // Changer toutes les 3 secondes
  }
} 
</script>

<style>
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.animated-button {
  animation: bounce 3s infinite;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;

}

.fade-enter,
.fade-leave-to {
  opacity: 1;

}


.image-container {
  position: relative;
}

.image-container img {

  filter: brightness(35%);
  /* Ajustez la valeur pour le niveau de luminosité souhaité */
}

.monospace {
  font-family: 'Courier New', Courier, monospace;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}

.chevron {
  color: red;
  /* Couleur du texte, ajustez selon vos besoins */
  margin: 0 5px;
  /* Marge entre les chevrons et le texte, ajustez selon vos besoins */
}</style>

