<script setup>

</script>

<template>
     <!-- Concept -->
     <div class="py-12 bg-white" id="concept" data-spy="scroll">
        <div class="mx-auto w-11/12 sm:w-3/5 md:w-3/4 xl:caontainer">
            <div class="w-full mx-auto xl:w-4/5">
                <h2 class="text-4xl xl:text-3xl font-bold text-indigo-950 my-4 xl:my-12">Un concept original.</h2>
            </div>
            <div class="flex flex-wrap gap-4 justify-center">                
                <div class="md:basis-2/5">
                    <div class="border mb-4 rounded-md shadow-md">
                        <div class="p-5">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-person-check text-amber-500" viewBox="0 0 16 16">
                                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                    <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                </svg>
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold  md:text-xl">Pas de profil type</h3>
                            <p class="mb-0 text-gray-600">
                                Quel que soit votre parcours académique et votre spécialité, nous croyons à
                                l’enrichissement de chacun et de tous par la diversité… Nous nous enrichissons de la
                                différence des autres car il existe de multiples chemins pour arriver au succès.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="md:basis-2/5">
                    <div class=" border mb-4 rounded-md shadow-md">
                        <div class=" p-5">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-mortarboard text-amber-500" viewBox="0 0 16 16">
                                    <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z"/>
                                    <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z"/>
                                </svg>
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold  md:text-xl">BAC +2 et concours</h3>
                            <p class="mb-0 text-gray-600">
                                Vous avez un niveau BAC +2 en poche ? Vous pouvez postuler. Nous sélectionnons sur
                                aptitudes ceux qui ont les appétences au métier de développeur et les caractéristiques
                                de personnalité nécessaires pour suivre ce cursus.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="md:basis-2/5">
                    <div class=" border mb-4 rounded-md shadow-md">
                        <div class=" p-5">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-bookmark-check text-amber-500" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                                </svg>
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold  md:text-xl">Pédagogie active</h3>
                            <p class="mb-0 text-gray-600">
                                Apprendre votre futur métier avec 10 % de cours théoriques et 90 % de pratique
                                individuelles et collectives, cela favorise la créativité, l’émulation et l’entraide. C’est
                                le principe même de l’apprentissage « militaire » (mode bootcamp) des 6 premiers mois :
                                vous vous surpassez, travailler en groupe, et pour une équipe.
                                À compter du septième mois vous renforcez ses acquis tout en travaillant un projet
                                d’ entreprise, sur des cas réels.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="md:basis-2/5">
                    <div class=" border mb-4 rounded-md shadow-md">
                        <div class=" p-5">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-eye text-amber-500" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold  md:text-xl">Suivi individuel</h3>
                            <p class="mb-0 text-gray-600">
                                Vous êtes unique. Nous avons à cœur de vous faire grandir en tenant compte de
                                votre personnalité et de vos atouts. C’est notre rôle durant ces 24 mois. Le vôtre est
                                d’être assidu au cours, de passer avec succès tous les examens hebdomadaires, d’être
                                exigeant avec vous-même. L’aventure que vous allez vivre, si vous êtes retenu, est très
                                prenante et elle commence au recrutement. Nous voulons les meilleurs !
                            </p>
                        </div>
                    </div>
                </div>
                <div class="md:basis-2/5">
                    <div class="border mb-4 rounded-md shadow-md">
                        <div class="p-5">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-laptop text-amber-500" viewBox="0 0 16 16">
                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"/>
                                </svg>
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold  md:text-xl">Pas de compétence informatique requise</h3>
                            <p class="mb-0 text-gray-600">
                                Venez avec votre envie, votre savoir être, votre ouverture aux autres et nous
                                nous chargerons de vous transmettre les compétences techniques et relationnelles pour
                                devenir un as du codage.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="md:basis-2/5">
                    <div class="border mb-4 rounded-md shadow-md">
                        <div class="p-5">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-cash text-amber-500" viewBox="0 0 16 16">
                                    <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                                    <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z"/>
                                </svg>
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold  md:text-xl">Frais de scolarité</h3>
                            <p class="mb-0 text-gray-600">
                                Rien à payer pour votre scolarité, à votre niveau. Seuls 10 000 FCFA de frais
                                d’inscription vous seront demandés au moment des épreuves du concours. Votre scolarité est prise en
                                charge par une entreprise qui va miser sur vous ! Vous devrez donc être impliqué dans votre
                                apprentissage. C’est l’excellence qui est visée.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="basis-full xl:basis-4/5">
                    <div class="border mb-4 rounded-md shadow-md">
                        <div class="p-5">
                            <div class="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-key text-amber-500" viewBox="0 0 16 16">
                                    <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
                                    <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </div>
                            <h3 class="mb-2 text-indigo-950 font-bold  md:text-xl">Garantie employabilité</h3>
                            <p class="mb-0 text-gray-600">
                                Mieux qu’un diplôme: HIGHFIVE University garantit un emploi pour chaque étudiant, sous
                                réserve qu’il ait passé avec succès ses examens… C’est la meilleure garantie d’excellence
                                que nous puissions apporter à nos étudiants et aucun autre établissement ne le propose.
                                Si vous êtes sélectionné, vous apprendrez un métier pour lequel les entreprises sont à
                                l’affut de talents…  Nous allons renforcer votre personnalité, vous apporter les compétences
                                et travailler vos capacités d’évolution et d’adaptation.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>