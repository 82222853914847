<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
const navs = [

  { id: 1, name: "Qui sommes-nous ?", route: "Accueil" },
  { id: 2, name: "Formation", route: "Formation" },
  { id: 3, name: "Nos engagements", route: "Engagements" },
  { id: 4, name: "Nous rejoindre", route: "JoinUs" },
  { id: 5, name: "Entreprise", route: "Entreprise" },
  { id: 6, name: "FAQ", route: "Faq" },
  { id: 7, name: "Séances d'information", route: "Reunions" },
];

const router = useRoute();

const menuVisible = ref(false);
const showClosebtn = ref(false);
const showMenubtn = ref(true);

const showMenu = () => {
  menuVisible.value = !menuVisible.value
  showClosebtn.value = true;
  showMenubtn.value = false;
}

const closeMenu = () => {
  menuVisible.value = !menuVisible.value
  showClosebtn.value = false;
  showMenubtn.value = true;
}
</script>

<template>
  <div class="shadow-xl sticky-nav">
    <div class=" container mx-auto flex justify-between items-center py-2 px-4 lg:px-20 ">


      <router-link to="/">
        <img src="../../assets/images/logo/logo.png" alt="logo" class="max-w-xs w-40 mx-2">
      </router-link>



      <nav class=" hidden xl:flex">
        <router-link v-for="nav in navs" :key="nav.id" :class="{ 'text-amber-600': router.name == nav.route }"
          class="active:bg-yellow-100 hover:text-amber-400   rounded px-3 p-3 text-base" :to="{ name: nav.route }">{{
            nav.name }}
        </router-link>
      </nav>


      <div class="flex gap-3">
        <router-link :to="{ name: 'Contact' }"
          class="hidden xl:flex rounded px-3 py-2 text-white text-center bg-amber-500 hover:bg-amber-600  ">Contact</router-link>

        <!-- <router-link :to="{ name: 'Reunions' }"
          class="hidden xl:flex rounded px-3 py-2 text-white text-center bg-amber-500 hover:bg-amber-600  ">Webinaires
        </router-link> -->
      </div>


      <div class="xl:hidden flex items-center">
        <button class="text-amber-500 flex" @click="showMenu" v-if="showClosebtn == false">
          <span>
            <svg fill="#f4a62a" xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 92 92" id="menu">
              <path
                d="M78 23.5H14c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5h64c3.6 0 6.5 2.9 6.5 6.5s-2.9 6.5-6.5 6.5zM84.5 46c0-3.6-2.9-6.5-6.5-6.5H14c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h64c3.6 0 6.5-2.9 6.5-6.5zm0 29c0-3.6-2.9-6.5-6.5-6.5H14c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h64c3.6 0 6.5-2.9 6.5-6.5z">
              </path>
            </svg>
          </span>
        </button>

        <button class="text-amber-500 flex" @click="closeMenu" v-if="showMenubtn == false">
          <span>
            <svg fill="#f4a62a" vxmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 512 512"
              id="close">
              <path
                d="M437.5 386.6L306.9 256l130.6-130.6c14.1-14.1 14.1-36.8 0-50.9-14.1-14.1-36.8-14.1-50.9 0L256 205.1 125.4 74.5c-14.1-14.1-36.8-14.1-50.9 0-14.1 14.1-14.1 36.8 0 50.9L205.1 256 74.5 386.6c-14.1 14.1-14.1 36.8 0 50.9 14.1 14.1 36.8 14.1 50.9 0L256 306.9l130.6 130.6c14.1 14.1 36.8 14.1 50.9 0 14-14.1 14-36.9 0-50.9z">
              </path>
            </svg>
          </span>
        </button>
      </div>

    </div>


    <div v-if="menuVisible"
      class="xl:hidden bg-white  mx-auto px-5 absolute top-[4rem]  left-2 right-2 pb-5 shadow-xl rounded-lg">
      <nav class="grid place-content-center">
        <router-link v-for="nav in navs" :key="nav.id" @click="closeMenu"
          class="active:bg-yellow-100 hover:text-amber-400 text-center focus:text-amber-500 rounded px-3 p-3 text-base"
          :to="{ name: nav.route }">{{ nav.name }}
        </router-link>
        <router-link :to="{ name: 'Contact' }" @click="closeMenu"
          class="rounded px-3 p-3 hover:text-amber-500 text-center focus:text-amber-500 ">Contact</router-link>
      </nav>
    </div>

  </div>
 
</template>
<style>
.sticky-nav {
  position: sticky;
  top: 0; /* La barre de navigation restera fixée en haut */
  z-index: 100; /* Ajustez la valeur si nécessaire pour la superposition avec d'autres éléments */
  background-color: #ffffff; /* Ajoutez une couleur de fond si nécessaire */
  /* ... Autres styles pour la barre de navigation ... */
}</style>
