<script setup>
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';
import { onMounted, ref } from 'vue';

const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
    scrollToTop();
});

const scrollToTop =() => {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

    }
}

</script>

<template>
    <!-- Guarantee -->
    <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />
    <div class=" flex" id="garantie" data-spy="scroll" ref="componentRef">

        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="pt-12 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <span class="uppercase text-yellow-500 font-semibold tracking-wider">Nos engagements</span>
                    <h2 class="text-4xl mb-3 font-bold mt-3">Garantie d’employabilité.</h2>
                    <p class="text-xl text-gray-600 my-4">
                        Mieux qu’un diplôme : HIGHFIVE University garantit un emploi pour chaque étudiant, sous réserve
                        qu’il ait passé avec succès ses examens. C’est la meilleure garantie d’excellence que nous puissions
                        apporter à nos étudiants et aucun autre établissement ne le propose.
                    </p>
                    <div class="grid grid-col-12 grid-row-2 gap-2 my-12">
                        <!-- gallery-item -->
                        <figure class="col-end-6 col-start-1 row-end-4 row-start-1 mb-0">
                            <img src="../assets/images/about/about-12.webp" alt="Gallery image 1" class="rounded-lg">
                        </figure>
                        <!-- gallery-item -->
                        <figure class="col-end-9 col-start-6 row-end-4 row-start-1 mb-0">
                            <img src="../assets/images/about/about-14.webp" alt="Gallery image 2"
                                class="rounded-lg object-cover h-full">
                        </figure>
                        <!-- gallery-item -->
                        <figure class="col-end-13 col-start-9 row-end-4 row-start-1 mb-0">
                            <img src="../assets/images/about/about-9.webp" alt="Gallery image 3"
                                class=" h-full object-cover rounded-lg">
                        </figure>
                        <!-- gallery-item -->
                        <figure class="col-start-1 row-start-4 row-end-7 col-end-4 mb-0">
                            <img src="../assets/images/about/about-10.webp" alt="Gallery image 4"
                                class="rounded-lg object-cover h-full">
                        </figure>
                        <!-- gallery-item -->
                        <figure class="col-end-9 col-start-4 row-end-7 row-start-4 mb-0">
                            <img src="../assets/images/about/about-11.webp" alt="Gallery image 5" class="rounded-lg">
                        </figure>
                        <!-- gallery-item -->
                        <figure class="col-end-13 col-start-9 row-end-7 row-start-4 mb-0">
                            <img src="../assets/images/about/about-8.webp" alt="Gallery image 6"
                                class="rounded-lg h-full object-cover w-ful">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Works -->
    <div class="pb-10 bg-white" id="futur-metier" data-spy="scroll">
        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="pb-10 flex justify-content-center align-items-center">
                <div class="container mx-auto">
                    <h2 class="text-4xl mb-3 font-bold mt-3">Votre futur métier</h2>
                    <p class="text-xl text-gray-600 my-4 mb-3">
                        Nos formations conduisent aux fonctions de « Développeur Codeur » qui recouvre des réalités très
                        différentes en fonction :
                    </p>
                    <ul class="list-disc pl-10">
                        <li class="text-xl text-gray-600 my-4 mb-3">
                            de la finalité du développeur front end (développe les éléments que les internautes voient sur
                            leur écran) : création de sites, d'applications, de programmation / back end : (développement de
                            ce que les internautes ne voient pas) : un serveur, une application,
                            une base de données, le mode de paiement sur un site e-commerce etc) / full-stack (front et back
                            end).
                        </li>
                        <li class="text-xl text-gray-600 my-4 mb-3">
                            des langages utilisés et des outils à maitriser : HTML, PHP, CSS, JavaScript, C#, SQL, Linux,
                            CMS : Wordpress, Drupal, Magento, Prestashop... Framework: Symfony, Laravel, React, .net…
                        </li>
                        <li class="text-xl text-gray-600 my-4 mb-3">
                            du type de client pour lequel le « Développeur Codeur » intervient : client interne à une
                            organisation, prestataire informatique en sous-traitance (cabinet conseil, agence…) et très
                            rarement de clients utilisateurs finaux).
                        </li>
                    </ul>
                    <p class="text-xl text-gray-600 my-4 mb-3">
                        Avec le soutien d’HIGHFIVE University, vous accédez à un niveau de « maitrise du code » qui vous
                        permet d’exercer la plupart des fonctions d’un développeur codeur.
                    </p>
                </div>
            </div>
            <div class="flex flex-wrap gap-4 justify-center">
                <div class="md:basis-2/5">
                    <div class="border rounded-md hover:shadow-md">
                        <div class="p-4 pb-1">
                            <div
                                class="w-12 h-12 border-2 border-primary rounded-full flex items-center border-yellow-500 text-yellow-500 justify-center shadow-md">
                                1</div>
                            <div class="my-4">
                                <ul class="list-none">
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Développeur / Intégrateur Web</li>
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Webmaster</li>
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Développeur front-end</li>
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Développeur back-end</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="md:basis-2/5">
                        <div class="border rounded-lg hover:shadow-md">
                            <div class="p-4 pb-1 w-[19rem]">
                                <div
                                    class="w-12 h-12 border-2 border-primary border-yellow-500 text-yellow-500 rounded-full flex items-center justify-center shadow-md">
                                    2</div>
                                <div class="my-4">
                                    <ul class="list-none">
                                        <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor"
                                                    class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg></span>Concepteur Développeur Web</li>
                                        <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor"
                                                    class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg></span>Chef Projet Web</li>
                                        <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor"
                                                    class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg></span>Développeur Full Stack</li>
                                        <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor"
                                                    class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg></span>Data Analyst</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md:basis-2/5">
                    <div class="border rounded-lg hover:shadow-md">
                        <div class="p-4 pb-1">
                            <div
                                class="w-12 h-12 border-2 border-primary border-yellow-500 text-yellow-500 rounded-full flex items-center justify-center shadow-md">
                                3</div>
                            <div class="my-4">
                                <ul class="list-none">
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Développeur mobile</li>
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Architecte d'application Web</li>
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Consulteur IT</li>
                                    <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor"
                                                class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                            </svg></span>Ingénieur d'étude et Développement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="md:basis-2/5">
                        <div class="border rounded-lg hover:shadow-md">
                            <div class="p-4 pb-1 h-[12.2rem]">
                                <div
                                    class="w-12 h-12 border-2 border-primary border-yellow-500 text-yellow-500 rounded-full flex items-center justify-center shadow-md">
                                    4</div>
                                <div class="my-4">
                                    <ul class="list-none">
                                        <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor"
                                                    class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg></span>Data scientist</li>
                                        <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor"
                                                    class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg></span>Chief Technical Officer</li>
                                        <li class="flex"><span><svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor"
                                                    class="w-4 h-6 mr-[.5rem] text-yellow-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                        d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg></span>Lead développeur</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Info student  -->
    <infostudent />
    <!-- Footer -->
    <pagefooter />
</template>

<script setup>

</script>