<script setup>
import infostudent from '@/components/Contents/InfoStudentComponent.vue';
import Pagefooter from '@/components/Contents/FooterComponent.vue';
import { onMounted, ref } from 'vue';
import ButtonTop from '@/components/partials/ButtonTop.vue';

const first_name = ref('');
const last_name = ref('');
const email = ref('');
const phone = ref('');
const subject = ref('');
const messagecontact = ref('');
const message = ref(false);
async function sendData() {
    let data =
    {

        first_name: first_name.value,
        last_name: last_name.value,
        phone: phone.value,
        subject: subject.value,
        email: email.value,
        message: messagecontact.value,

    }

    // fetch(`http://localhost/highfive_site/backend/route.php?goto=contact&target=contact_save`,
    fetch(`/backend/route.php?goto=contact&target=contact_save`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
    ).then(response => response.json()).then(response => {
        message.value = response;
    })
}

const componentRef = ref(null);

// Faites défiler la vue en haut lorsque le composant est monté
onMounted(() => {
    scrollToTop();
});

const scrollToTop =() => {
    if (componentRef.value) {
        componentRef.value.scrollIntoView({ behavior: 'smooth', block: "start" });

    }
}

</script>
<template>
    <ButtonTop class="fixed bottom-8 right-8 bg-sky-500 rounded-full w-10 h-10 flex justify-center items-center" />
    <div id="top" class="py-6 py-lg-12 bg-white" ref="componentRef">

        <div class="w-11/12 mx-auto sm:w-4/5 md:w-9/12 xl:w-3/5">
            <div class="" v-if="message">
                <div class="w-full">
                    <div class="text-white bg-green-400 py-8 mx-auto text-5xl text-center" v-if="message.code == 200">
                        Votre demande a bien été transmise. Merci!
                    </div>
                </div>
            </div>
            <form @submit.prevent="sendData" v-if="!message">
                <div class="">

                    <!-- Title -->
                    <div class=" w-full">
                        <div class="mb-4 ">
                            <span class="uppercase font-bold text-amber-500">Contactez-nous</span>
                            <h1 class="flex text-4xl xl:text-4xl font-bold text-indigo-950 my-4">Formulaire de contact</h1>
                            <p class="mb-0 text-xl text-gray-600 my-4">
                                Vous pouvez nous contacter via ce formulaire : une réponse vous sera adressée dans les
                                meilleurs délais.
                            </p>
                        </div>
                    </div>

                    <div class="  w-full">
                        <div class="mb-4  ">
                            <div class="">
                                <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                    <div class="mb-3">
                                        <label for="fname">Prénom<span class="text-red-500">*</span></label>
                                        <input type="text" placeholder="John"
                                            class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                            v-model="first_name">
                                    </div>
                                    <div class="mb-3">
                                        <label for="lname">Nom<span class="text-red-500">*</span></label>
                                        <input type="text" placeholder="Doe"
                                            class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                            v-model="last_name">
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                                    <div class="mb-3">
                                        <label for="email">Email<span class="text-red-500">*</span></label>
                                        <input type="email" placeholder="john.doe@gmail.com"
                                            class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                            v-model="email">
                                    </div>
                                    <div class="mb-3">
                                        <label for="phone">Téléphone<span class="text-red-500">*</span></label>
                                        <input type="tel" placeholder="Ex: 44885522"
                                            class="w-full mt-[.5rem] border p-3 rounded-lg  text-gray-600" required
                                            v-model="phone">
                                    </div>
                                </div>
                                <div class="mb-3 2">
                                    <label for="subject">Sujet<span class="text-red-500">*</span></label>
                                    <input type="text" placeholder="Sujet de votre demande"
                                        class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" required
                                        v-model="subject">
                                </div>
                                <div class="mb-3 2">
                                    <label for="message">Message<span class="text-red-500">*</span></label>
                                    <textarea class="w-full mt-[.5rem] border p-3 rounded-lg text-gray-600" name="Message"
                                        id="" cols="20" rows="5" placeholder="Votre message..." required
                                        v-model="messagecontact"></textarea>
                                </div>
                                <div class="col-span-2 mt-3">
                                    <button
                                        class="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600">Envoyer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Info student  -->
    <infostudent />
    <!-- Footer -->
    <pagefooter />
</template>









